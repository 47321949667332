import PropTypes from "fusion-prop-types";
/**
 * NOTE: In order to keep PropTypes.siteServiceSection from
 * erroring the page out, we need to point to fusion-prop-types
 * in next.config.js which points to next-fusion-compat/fusion-modules/prop-types.js
 *
 * That file extends the proptypes coming from arc-fusion + React
 * */
export const HomepagePropTypes = {
  children: PropTypes.any,
  customFields: PropTypes.shape({
    displayName: PropTypes.string.tag({
      hidden: true
    }),
    siteServiceSection: PropTypes.siteServiceSection.tag({
      name: "Section",
      defaultValue: "homepage"
    }),
    enableMultiView: PropTypes.bool.tag({
      name: "Enable Tab Multi-View",
      defaultValue: false,
      description:
        "If true, will display 3 tabs on mobile breakpoint for main content, latest headlines, and for you stories. Should be enabled only for homepage."
    })
  })
};
