import get from "lodash.get";
import {
  createDesktopOrderingObj,
  getGridInfo,
  getHiddenChainInfo
} from "../components/chains/top-table/utilities/data-transformation";
import { fixLayoutName } from "../components/chains/top-table/utilities/settings";
import { useForYouTopics } from "../content/sources/foryou-topics";
import getEnv from "~/components/utilities/env";
import { useAppContext } from "~/spartan-homepage/assembler-render";
import { isAuthenticated } from "../components/utilities/login-details";

export const normalizeTopic = (v) =>
  v
    ? v
        .split(",")
        .map((_) => _.trim())
        .sort()
        .join(",")
    : v;

/*
 * @param {string} id - Assembler id
 * @param {object} tree - Assembler tree object
 * @returns {object} - Either,
 *   - if the object with the id exists, customFields
 *   - otherwise, {}
 */
export const getCustomFields = (id, tree) =>
  get(tree, `[${id}]props.customFields`, {});

const isHidden = (customFields, bp) => {
  let { layout } = customFields;
  layout = fixLayoutName(layout);
  if (layout) {
    const GRID_INFO = getGridInfo({ bp, includeDividers: false });
    const desktopOrderingObj = createDesktopOrderingObj(
      GRID_INFO,
      customFields.layout
    );
    const hiddenChainInfo = getHiddenChainInfo(
      desktopOrderingObj,
      customFields
    );
    return get(hiddenChainInfo[bp], "hide", false);
  }
  // NOTE: If not a chain or somehow a chain w/o a layout
  const { hideFromWeb = false } = customFields;
  return hideFromWeb;
};

/*
 * @param {object} tree - Assembler tree object
 * @param {string} id - Assembler id
 * @returns {boolean}
 */
const isEmpty = (id, tree) => get(tree[id], "children", []).length === 0;

/*
 * @param {object} customFields -- check for valid keyy/values. All spaces is not a valid topic
 * @param {string} bp - breakpoint, e.g. "mx"
 * @param {object} tree - Assembler tree object
 * @param {string} id - Assembler id
 * @returns {boolean}
 */
export const isOrderable = (customFields, bp, id, tree) => {
  if (!customFields) return false;
  return (
    /\S/.test(customFields.mappedTopicName) &&
    customFields.personalizedOrdering === true &&
    !isHidden(customFields, bp) &&
    !isEmpty(id, tree)
  );
};

export const getTopicFromChild = (child, tree, bp) => {
  const customFields = getCustomFields(child.id, tree);
  return isOrderable(customFields, bp, child.id, tree)
    ? normalizeTopic(customFields.mappedTopicName)
    : undefined;
};

/*
 * Maps the Assembler-generated list of children to their corresponding topic
 * @param {array} children - Assembler generated list [{ id }]
 * @param {object} tree - tree with info about each child with id
 * @returns {object} - list
 *   [
 *     normalized string topic || undefined
 *   ]
 */
export const getTopicOrNot = (children, tree, bp) =>
  children.map((child) => {
    return getTopicFromChild(child, tree, bp);
  });

/*
 * Given the topic content, will return a simplified object
 * @param {obj} content - for-you-api sections content
 * @returns {object} - of the form
 *   {
 *     topic: n
 *     ...
 *   }
 */
export const getTopicToCountMap = (content) => {
  return (content || []).reduce((acc, o) => {
    const key = o.section;
    acc[key] = o.score;
    return acc;
  }, {});
};

/*
 * Fetches the topic content
 *
 * @returns {array} - A map of of the form
 *  {
 *    topic1: n,
 *    topic2: m,
 *    ...
 *  }
 */
export const useUserTopicToCountMap = () => {
  const { isAdmin } = useAppContext();
  const content = useForYouTopics({
    env: getEnv(),
    includeCredentials: isAdmin ? false : isAuthenticated()
    // NOTE: set debug to true locally to force meaningful results
    // SEE: ~/pages/api/foryou-topics.js
    // debug: true
  });
  return getTopicToCountMap(content);
};

/*
 * @param {object} map - topic to count map
 * @returns {boolean} - whether the map has any topics or not
 */
export const hasTopics = (map) => !!Object.keys(map).length;

/*
 * Given a list of topics where each topic in the list could be a comma-separated string
 * of topics and a map of topics to counts, returns a new object where each key is
 * one of the items in the topics list
 * @param {topics} topics - list of topics
 * @param {object} map - object of the form returned by getTopicToCountMap
 * @returns {object} - of the form
 *   {
 *     topic1: n1,
 *     topic2,topic3,topic4: n2 + n3 + n4
 *     ...
 *   }
 */
export const getMultiTopicToCountMap = (topics, map) => {
  const topicsInMap = Object.keys(map);
  return topics.reduce((acc, topic) => {
    const multiTopics = topic.split(",");
    if (
      multiTopics.length > 1 &&
      multiTopics.some((thisTopic) => topicsInMap.includes(thisTopic))
    ) {
      acc[topic] = multiTopics.reduce((total, thisTopic) => {
        return total + get(map, thisTopic, 0);
      }, 0);
    }
    return acc;
  }, {});
};

/*
 * Given a topicToCountMap and a list<string> of topics will return a list<string> of topics
 * in the map
 * with topic combinations due to any chains that are tagged with multiple topics.
 * @param {object} map - A topicToCount map
 * @returns {object} - A list of topics
 *
 * Example: If the map is { "topic1": 7, "topic2": 7, "topic3": 10 } and there are
 * chains tagged: 'topic1,topic2', 'topic2' and 'topic3'
 * the list returned will be: ["topic1,topic2", "topic3", "topic2"].
 */
export const getUserTopics = (map, topics) => {
  const multiTopicToCountMap = getMultiTopicToCountMap(topics, map);
  const combinedMap = { ...map, ...multiTopicToCountMap };
  return Object.keys(combinedMap)
    .filter((_) => topics.includes(_))
    .sort((a, b) => combinedMap[b] - combinedMap[a]);
};

/*
 * @param {array} nodes - The nodes that are marked as being orderable
 * @returns {array} -- list<string> of normalized topics
 */
export const getOrderableTopics = (topicOrNot) => {
  return topicOrNot.reduce((acc, topic) => {
    if (topic && !acc.includes(topic)) acc.push(topic);
    return acc;
  }, []);
};

/*
 * Given a list of html nodes, find the ones eligible for re-ordering
 * and return an object keyed by topic, where each topic has info about
 * it needed later, in particular, the order at which the given node
 * appeared in the list of nodes. The same topic may appear more than once.
 * @param {array} nodes - list of nodes
 * @returns {object} - of the form
 *   {
 *     topic: { order: [n1, n2, ...]}
 *   }
 */
export const getTopicInfo = (topicOrNot) => {
  return topicOrNot.reduce((acc, topic, i) => {
    if (topic) {
      if (!acc[topic]) acc[topic] = { order: [] };
      acc[topic].order.push(i);
    }
    return acc;
  }, {});
};

/*
 * Given a topicInfo object as returned by the above function, return
 * a new object. Each topic leads to n keys in the object where n is
 * the number of times the topic appears in the chain and the value is
 * the order at which it appeared
 * @param {object} topicInfo - An object with info about a topic
 * @returns {object} - of the form
 *   {
 *     topic1-0: order1,
 *     topic2-0: order2,
 *     topic2-1: order3
 *   }
 */
export const getTopicToOrderMap = (topicInfo) => {
  return Object.keys(topicInfo).reduce((acc, key) => {
    const topic = topicInfo[key];
    topic.order.forEach((n, i) => {
      acc = { ...acc, [`${key}-${i}`]: n };
    });
    return acc;
  }, {});
};

/*
 * Given a topicInfo object as returned by the above function and a list of
 * referred topics return the list of user topics, repeated n times where
 * n is the number of times the topic appeared, i.e. order.length
 *
 * @param {object} topicInfo - An object with info about a topic
 * @param {array} topics - A list<string> of topics
 * @returns {array} - of the form
 *   [
 *     topic1-0, // topic1, 1st occurrence
 *     topic2-0, // topic2, 1st occurrence
 *     topic2-1, // topic2, 2nd occurrence
 *     ...
 *     topic2-n, // topic2, nth occurrence
 *     topic3-0, // topic3, 1st occurrence
 *     ...
 *   ]
 */
export const getEnhancedUserTopics = (topicInfo, topics) => {
  return topics
    .map((key) => {
      const topic = topicInfo[key];
      return topic?.order
        ? new Array(topic.order.length).fill().map((_, i) => `${key}-${i}`)
        : [key];
    })
    .flat(2);
};

/*
 * Give two lists of topics, combines them w/o repeats
 * @param {array} topic1
 * @param {array} topic2
 * @returns {array} of topics
 */
export const getOrderedTopics = (topics1, topics2) => [
  ...topics1.filter((topic) => topics2.includes(topic)),
  ...topics2.filter((topic) => !topics1.includes(topic))
];

/*
 * Given a topicToOrderMap and list of topics, map each value in topics
 * to its user order in the topicToOrderMap.
 * @param {object} map - topicToOrderMap
 * @param {array} topics - list<string> of topics
 * @returns {object} - of the form
 *   {
 *     topic1-0: order1,
 *     topic2-0: order2,
 *     topic2-1: order3
 *   }
 */
export const getTopicToUserOrderMap = (map, topics) => {
  const orders = Object.keys(map)
    .map((key) => map[key])
    .sort((a, b) => a - b);
  return topics.reduce((acc, topic, i) => {
    acc[topic] = orders[i];
    return acc;
  }, {});
};

export const getFinalOrder = (topicOrNot, topicInfo, map) => {
  // NOTE: For tracking the number of times the same topic has been handled
  // so topic-0, topic-1, etc. can be generated
  const numHandled = Object.keys(topicInfo).reduce((acc, key) => {
    acc[key] = 0;
    return acc;
  }, {});

  // NOTE: If a not a topic, order is its "natural" position
  return topicOrNot.map((topic, i) => {
    const info = topicInfo[topic];
    const order = info ? map[`${topic}-${numHandled[topic]}`] : i;
    if (info) numHandled[topic] += 1;

    // OBSERVE: The holy grail!
    return order;
  });
};

/*
 * Given a list of Assembler-generated children and tree, and user preferences
 * create list of indexes represeting the new order of the children
 * @param {array} chains - list of (simplified) chains objecst
 * @param {object} userToTopicCountMap - mapping of topics for a user
 * @param {string} bp - breakpoint, e.g. "mx"
 * @returns {array} - list of indexes
 */
export const getOrder = (children, tree, userTopicToCountMap, bp) => {
  let order = [];
  const topicOrNot = getTopicOrNot(children, tree, bp);
  const orderableTopics = getOrderableTopics(topicOrNot);
  const userTopics = getUserTopics(userTopicToCountMap, orderableTopics);
  if (userTopics.length && orderableTopics.length) {
    const topicInfo = getTopicInfo(topicOrNot);
    const topicToOrderMap = getTopicToOrderMap(topicInfo);
    const userTopicsEnhanced = getEnhancedUserTopics(topicInfo, userTopics);
    const topicsEnhanced = Object.keys(topicToOrderMap);
    const orderedTopics = getOrderedTopics(userTopicsEnhanced, topicsEnhanced);
    const topicToUserOrderMap = getTopicToUserOrderMap(
      topicToOrderMap,
      orderedTopics
    );
    order = getFinalOrder(topicOrNot, topicInfo, topicToUserOrderMap);
  }
  return order;
};

/*
 * Given a list of Assembler-generated children and tree, and user preferences
 * create reordered list of children
 * @param {array} chains - list of (simplified) chains objecst
 * @param {object} userToTopicCountMap - mapping of topics for a user
 * @param {string} bp - breakpoint, e.g. "mx"
 * @returns {array} - list of indexes
 */
export const getReorderedChildren = (
  children,
  tree,
  userTopicToCountMap,
  bp
) => {
  const order = getOrder(children, tree, userTopicToCountMap, bp);
  if (order && order.length && order.length === children.length) {
    const reorderedChildren = new Array(order.length);
    order.forEach((reorderedIdx, originalIdx) => {
      reorderedChildren[reorderedIdx] = children[originalIdx];
    });
    return reorderedChildren;
  }
  return undefined;
};
