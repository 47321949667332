import getCookie from "./cookies";
import { hasLocalStorage } from "~/components/utilities/hasLocalStorage";

export const getLoginDetails = () => {
  return {
    wapoSecureID: getCookie("wapo_secure_login_id"),
    wapoLoginID: getCookie("wapo_login_id"),
    userAgent:
      typeof window !== "undefined" ? window.navigator.userAgent : undefined
  };
};

export const getProvider = () => {
  return decodeURIComponent(getCookie("wapo_provider"));
};

export const isSubFromActmgmtCookie = () => {
  const cookieValue = getCookie("wapo_actmgmt");

  if (cookieValue) {
    const isub = cookieValue
      .split("|")
      .filter((item) => /^isub:/.test(item))[0];
    if (isub) {
      return !!parseInt(isub.charAt(isub.length - 1), 10);
    }
  }

  return false;
};

/**
 * Get the user ID
 *
 * @returns {string} value of wapo_login_id
 */
export function getUserId() {
  try {
    return getCookie("wapo_login_id") || "";
  } catch (e) {
    // do nothing identity can't be found;
    return undefined;
  }
}

/**
 * Get the secure user ID
 *
 * @returns {string} value of wapo_secure_login_id
 */
export function getSecureUserId() {
  try {
    return getCookie("wapo_secure_login_id") || "";
  } catch (e) {
    // do nothing identity can't be found;
    return undefined;
  }
}

/**
 * Get a logged in users name
 *
 * @returns {string} user name or empty string
 */
export function getUserName() {
  let userName = "";
  const displayInfo = getCookie("wapo_display");
  if (displayInfo) {
    const userData = displayInfo.split("|");
    if (userData.length > 0) {
      if (userData[3]) {
        userName = userData[3];
      } else if (userData[2] && userData[2].indexOf("rw%3D") === -1) {
        userName = userData[2];
      } else {
        userName = userData[0];
      }

      userName = decodeURIComponent(userName);
      userName = userName.replace(/"/g, "");
      userName = userName.replace(/\+/g, " ");
    }
  }
  return userName;
}

/**
 * Check if the user is authenticated
 *
 * @returns {bool} authenticated status
 */
export function isAuthenticated() {
  return !!getUserId() && !!getSecureUserId();
}

/**
 * Check if a user is a subscriber
 *
 * @returns {bool} subscriber status
 */
export function isSubscriber() {
  if (isAuthenticated()) {
    const actmgmt = getCookie("wapo_actmgmt");
    return actmgmt && actmgmt.indexOf("isub:1") !== -1;
  }
  return false;
}

/**
 * Plz don't use anywhere other than where it's currently used.  Temp solution to a broader problem
 */
// eslint-disable-next-line no-underscore-dangle
export function isSubscriberForCTA() {
  // for this one usecase, if the cookie isn't there, don't show the CTA's
  if (isAuthenticated()) {
    const actmgmt = getCookie("wapo_actmgmt");
    // returns true because in this case we're pretending they are a subscriber
    if (!actmgmt) {
      return true;
    }
    return actmgmt.indexOf("isub:1") !== -1;
  }
  return false;
}

/**
 * Check if the user has an ad free subscription
 *
 * @returns {bool} true if the user has an ad free subscription
 */
export function isAdFree() {
  const wapoActmgmtCookie = getCookie("wapo_actmgmt");

  // @TODO Remove this code and load ads in My Post using Newsreader
  let isMyPost = false;
  if (typeof window !== "undefined") {
    const url = window && window.location && window.location.href;
    isMyPost = url.indexOf("my-post") > -1;
  }

  // Check if a user is in the EU and also should not see ads
  const isEUAdFree = /(\b|.*\|)EU_NOADS:1([^;]*)/.test(wapoActmgmtCookie);

  // Check if a user is in the US and also should not see ads
  const isUSAdFree =
    /(\b|.*\|)NOADS:1([^;]*)/.test(wapoActmgmtCookie) || isMyPost;

  return !!(isUSAdFree || isEUAdFree);
}

const LoginDetails = getLoginDetails();

export default LoginDetails;

export function subscriberType() {
  let subText = "";
  const getUserType = getCookie("wapo_actmgmt");

  if (getUserType !== null) {
    if (getUserType.indexOf("PROD_DISPLAY") !== -1) {
      const prodDisplay = document.cookie.match(
        /wapo_actmgmt=(\b|.*)PROD_DISPLAY:([^|";]+)("|;|\b|\|)([^;]*);/
      )
        ? RegExp.$2
        : "";
      subText = decodeURIComponent(prodDisplay);
    } else {
      if (getUserType.indexOf("DSI") !== -1) {
        subText = "Home Delivery +";
      }
      if (getUserType.indexOf("PREMIUM") !== -1) {
        subText += " Premium";
      }
      if (getUserType.indexOf("BASIC") !== -1) {
        subText += " Basic";
      }
      subText += " digital subscriber";
    }
  }
  return subText;
}

/**
 * @func getJucid
 * @desc retrieve a UUID from localStorage; this has been renamed to j_ucid in most other applications
 *   if there is none, generate one save it to localStorage and return the value
 * @return {String}
 */
export function getJucid() {
  if (!hasLocalStorage()) return undefined;
  let jUcid = localStorage.getItem("uuid");
  if (!jUcid) {
    jUcid = crypto.randomUUID(); // https://codeql.github.com/codeql-query-help/javascript/js-insecure-randomness/
    localStorage.setItem("uuid", jUcid);
  }
  return jUcid;
}
