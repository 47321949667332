import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";
import { getLink } from "~/shared-components/story-card/_utilities/helpers";
import { removeWWW } from "../utilities/www-remover";

const RenderedContentContext = createContext({});

function updateRenderedContentKey({
  renderedContent,
  key,
  listProperties,
  singletonProperties
}) {
  renderedContent[key] = renderedContent[key] || {};
  Object.entries(listProperties).forEach(([listKey, listValue]) => {
    if (listValue && !renderedContent[key][listKey]?.includes(listValue)) {
      renderedContent[key][listKey] = [
        ...(renderedContent[key][listKey] || []),
        listValue
      ];
    }
  });
  renderedContent[key] = { ...renderedContent[key], ...singletonProperties };
}

function RenderedContentProvider({ children }) {
  /**
   * Provide information about the content rendered on the page, for the purposes of deduping.
   *
   * `renderedContent` is a map-like object of the type:
   *
   * {
   *   [link]: {
   *     featureIds: string[],
   *     chainDisplayNames: string[],
   *     canonical_url: string
   *   }
   * }
   *
   * The intention is that "all" rendered content will end up in here, via:
   * - StoryCards
   * - Carousels
   * Content like breaking news, newsletters, or anything else that doesn't use the above will not end up here.
   */
  const [renderedContent, setRenderedContent] = React.useState({});

  const getRenderedContentId = ({ content, overrides }) =>
    removeWWW(getLink({ content, overrides })?.link?.url);

  function updateRenderedContent({ items, id, displayName, overrides }) {
    setRenderedContent((existingContent) => {
      if (!items) return existingContent;
      items.forEach((content) => {
        const key = getRenderedContentId({ content, overrides });
        const canonical_url = removeWWW(content?.canonical_url);
        updateRenderedContentKey({
          renderedContent: existingContent,
          key,
          listProperties: { featureIds: id, chainDisplayNames: displayName },
          singletonProperties: { canonical_url }
        });
      });
      return existingContent;
    });
  }

  function getRenderedContent(filters) {
    return Object.entries(renderedContent).filter(([key]) => {
      return Object.entries(filters).some(([filterKey, filterValues]) => {
        return filterValues.some((filterValue) => {
          return renderedContent[key][filterKey]?.includes(filterValue);
        });
      });
    });
  }

  return (
    <RenderedContentContext.Provider
      value={{
        renderedContent,
        getRenderedContentId,
        getRenderedContent,
        updateRenderedContent
      }}
    >
      {children}
    </RenderedContentContext.Provider>
  );
}

RenderedContentProvider.propTypes = {
  children: PropTypes.any
};

const { Consumer: RenderedContentConsumer } = RenderedContentContext;

function useRenderedContentContext() {
  return useContext(RenderedContentContext);
}

export {
  useRenderedContentContext,
  RenderedContentProvider,
  RenderedContentConsumer
};
