// Defaults for any custom fields, so they can be viewed in one place
const PRESETS = {
  lastPickedPreset: "⟲ Head + byline (Clear all Custom Fields)",
  lastPickedMiniPreset: "",
  mobilePreset: "– Hide blurb and image"
};

const DECORATION = {
  textAlignment: "left",
  textVerticalAlignment: "top",
  horizontalDividerWeight: "normal",
  verticalDividerWeight: "normal",
  hideOnDesktop: false
};

const COUNT = {
  countShow: false,
  countOverride: 0,
  countSize: "auto"
};

const TOPPER_LABEL = {
  topperLabelShow: false,
  topperLabelType: "Package",
  topperLabel: "",
  topperLabelLinkRemove: false,
  topperLabelUrl: "",
  topperLabelSecondaryShow: false,
  topperLabelSecondary: "",
  topperLabelAlignment: "inherit",
  topperLabelIcon: "None",
  topperLabelNameSpace: "topper"
};

const LABEL = {
  labelShow: false,
  labelType: "Kicker",
  labelPosition: "Above Headline",
  label: "",
  labelLinkRemove: false,
  labelUrl: "",
  labelSecondaryShow: false,
  labelSecondary: "",
  labelAlignment: "inherit",
  labelIcon: "None"
};

const ART = {
  alternateArt: "",
  inlineVideo: false,
  coverArtUrl: "",
  coverArtWidth: "",
  coverArtHeight: "",
  coverArtCaption: "",
  coverArtAltText: "",
  coverArtIsLive: false,
  artOverlayShow: false,
  artOverlayText: "",
  includeDurationOrLive: true,
  alternateArtLinkEnable: false,
  alternateArtLink: "",
  artImageFallback: "default"
};

const ART_LAYOUT = {
  artHide: true,
  artPosition: "Art right of head",
  artSize: "Medium",
  artAspectRatio: "3:2",
  wrapText: true,
  artUseHiRes: false,
  artMakeItRound: "never"
};

// NOTE: Add cardify-specific custom fields here and make sure
// they start with "cardify". They will then automatically end
// up in the cardInfo object available from jsonapp.cardify.js
const CARDIFY = {
  cardifyForcedBleedType: "default"
};

const SLIDESHOW = {
  slideshowShow: true,
  slideshowUrls: "",
  slideshowAutoplay: true,
  slideshowSlideDuration: 7,
  slideshowTransitionDuration: 1.5,
  slideshowMaxLoops: 100,
  slideshowDots: "touch",
  slideshowScalingStrategy: "aspect-fill",
  slideshowUseSameCaption: false
};

const CAPTION = {
  captionContent: "None"
};

// NOTE: Sadly, the video custom fields are not prepended with "video"
const VIDEO = {
  allowAnimation: false,
  autoplay: false,
  muted: false,
  ads: true
};

const HEADLINE = {
  deckShow: false,
  headline: "",
  headlineAlignment: "inherit",
  headlineHide: false,
  headlinePrependBullet: false,
  headlinePrefixShow: false,
  headlineSize: "Small",
  headlineStyle: "Bold",
  headlineFontFamily: "Default"
};

const AUDIO = {
  audioHide: false,
  audioAllowArticleType: "human",
  audioCanonicalUrl: "",
  audioUrl: "",
  audioListenText: "",
  audioPosition: "Default",
  audioArtOverlayShow: false
};

const BLURB = {
  blurbHide: true,
  descriptionText: "",
  blurbNumExtrasToShow: 0,
  blurbOrderOfExtras: ""
};

const BYLINE = {
  siglineHide: false,
  bylineShow: true,
  bylineShowOpinion: false,
  bylineUseAlternate: false,
  timestampShow: true,
  timestamp: "",
  timestampDuration: 2,
  timestampRecencyThreshold: 30,
  timestampUseFixedDateFormat: false,
  timestampFixedDateFormat: "EEEE, MMMM d, 'at' h aaaa z"
};

const RELATED_LINKS = {
  relatedLinksHide: false,
  relatedLinksPosition: "Default",
  relatedLinksNum: 0,
  relatedLinksOrder: "",
  relatedLinksSize: "Related links",
  relatedLinksStyle: "Stacked",
  relatedLinksLabel: "",
  relatedLinksLabelType: "Kicker",
  relatedLinksLabelNameSpace: "relatedLinks"
};

const LIVE_TICKER = {
  liveTickerHide: true,
  liveTickerCanonicalUrl: "",
  liveTickerNum: 3,
  liveTickerUpdatesShow: true,
  liveTickerOutcomesShow: true,
  liveTickerRaceCallsShow: true,
  liveTickerReporterInsightsShow: true,
  liveTickerTimestampShow: true,
  liveTickerLabel: "",
  liveTickerLabelType: "Live Updates",
  liveTickerLabelNameSpace: "liveTicker"
};

const RECIPE = {
  recipeShow: false
};

const CTA = {
  ctaLabelShow: false,
  ctaLabelType: "CTA",
  ctaLabel: "",
  ctaLabelUrl: "",
  ctaLabelLinkRemove: false,
  ctaLabelSecondaryShow: false,
  ctaLabelSecondary: "",
  ctaLabelAlignment: "inherit",
  ctaLabelIcon: "None",
  ctaLabelNameSpace: "cta"
};

const EXCERPT = {
  excerptHide: true,
  excerptText: ""
};

const FOOTNOTE = {
  footnoteHide: true,
  footnoteText: "",
  footnoteUrl: "",
  footnoteAlignment: "right"
};

const LIVE_GRAPHICS = {
  liveGraphicsHide: false,
  liveGraphicsContentConfig: {
    contentService: "no-content",
    contentConfigValues: {
      id: ""
    }
  },
  liveGraphicsUseScreenshot: false
};

const FEED = { limit: 5, offset: 0 };

// NOTE: Within first order of approximation, be sure that the mobile only propnames match
// an existing propname but appended with "MobileOnly". Blank is a good value to start with,
// though non-text fields might require something else. For numbers, the newsroom wants blank
// to mean inherit from desktop. Hence, the corresponding MobileOnly fields should be strings
// b/c Assembler won't let you set a number field to blank. Care must be taken to coerce these
// fields into numbers when the value is parseable into a number.
const MOBILE_ONLY = {
  alternateArtMobileOnly: "",
  artAspectRatioMobileOnly: "",
  headlineMobileOnly: "",
  relatedLinksNumMobileOnly: "", // normal type is number. this will be string
  liveTickerNumMobileOnly: "", // normal type is number. this will be string
  limitMobileOnly: "" // normal type is number. this will be string
};

export const FLEX_FIELD_DEFAULTS = {
  displayName: "Flex Feature",
  rowSpan: 4,
  ...PRESETS,
  ...DECORATION,
  ...COUNT,
  ...TOPPER_LABEL,
  ...LABEL,
  ...ART,
  ...ART_LAYOUT,
  ...SLIDESHOW,
  ...CAPTION,
  ...VIDEO,
  ...HEADLINE,
  ...AUDIO,
  ...EXCERPT,
  ...BLURB,
  ...BYLINE,
  ...RELATED_LINKS,
  ...LIVE_TICKER,
  ...LIVE_GRAPHICS,
  ...CTA,
  ...FOOTNOTE,
  ...FEED,
  ...CARDIFY,
  ...MOBILE_ONLY
};

export const FLEX_FIELD_DEFAULTS_BY_GROUP = {
  displayName: "Flex Feature",
  rowSpan: 4,
  PRESETS,
  DECORATION,
  COUNT,
  LABEL,
  ART,
  ART_LAYOUT,
  SLIDESHOW,
  CAPTION,
  VIDEO,
  HEADLINE,
  AUDIO,
  BLURB,
  BYLINE,
  RELATED_LINKS,
  LIVE_TICKER,
  LIVE_GRAPHICS,
  RECIPE,
  CTA,
  FOOTNOTE,
  FEED,
  CARDIFY,
  MOBILE_ONLY
};

export const FLEX_FIELD_KEYNAMES = Object.keys(FLEX_FIELD_DEFAULTS);

const defaults = {
  ...FLEX_FIELD_DEFAULTS
};

export default defaults;
