import React, { Fragment } from "react";
import PropTypes from "prop-types";

/* Utilities */
import { getClasses } from "@washingtonpost/front-end-utils";

import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import { listOfValidIcons, getIconStyle } from "./Label.helpers";

import Form from "./Form";
import { getIcon } from "./Icons";
import { logos, getLogo } from "./Logos";

/* Components */
import { ConditionalWrapper } from "../_utilities/components";

const sizes = ["tiny", "small", "medium", "normal", "large", "x-large"];
const styles = ["normal", "italic"];
const weights = ["normal", "bold"];
const displays = ["", "db", "dib"];
// NOTE: colors/(hover)backgroundColors should be DSM colors
const darkColors = ["black", "offblack", "gray-darkest", "gray-dark", "gray"];
const colors = ["", "red", "white", ...darkColors];
const backgroundColors = ["", "offblack", "red"];
const hoverBackgroundColors = {
  red: "hover-bg-red-dark",
  offblack: "hover-bg-gray-dark"
};

const defaultTextColor = "gray-darkest";

const getPaddingBottomClass = (type) => {
  switch (type) {
    case "package":
    case "package-nested":
      return "mb-sm";
    case "kicker":
      return "mb-xxs";
    default:
      return "mb-xs";
  }
};

// NOTE: color/backgroundColor should be DSM colors
const Label = ({
  url,
  text,
  size = "medium",
  style = "normal",
  serif = false,
  weight = "bold",
  display = "",
  color = "offblack",
  backgroundColor = "",
  icon = "",
  innerIcon = "",
  showArrow = false,
  arrowIcon = "Arrow",
  toUpperCase = false,
  classes = "",
  editablePropertyInfo = {}
}) => {
  const { EditableProperty } = useAssemblerContext();
  if (!text) return null;

  const fontSizeMap = {
    "x-large": "md",
    large: "sm",
    normal: "xs",
    medium: "xxs",
    small: "xxxs",
    tiny: "xxxxs"
  };

  const iconIsLogo = Object.keys(logos).includes(icon);
  const isPill = !!backgroundColor;
  const isLinked = !!url;

  const fontSizeClass = `font-${fontSizeMap[size]}`;
  const fontWeightClass = weight.match(/^(bold)$/) ? "font-bold" : "";
  const fontStyleClass = !style.match(/^(normal)$/) ? style : "";
  const fontSerifClass = serif ? "lh-default font-family-georgia" : "";
  const fontColorClass = color;
  const fontBgColorClass = getClasses("", {
    [backgroundColor]:
      backgroundColor && backgroundColors.includes(backgroundColor),
    [hoverBackgroundColors[backgroundColor]]:
      isLinked && hoverBackgroundColors[backgroundColor]
  });
  const pillClasses = getClasses("", {
    "brad-11 pr-xs pl-xs lh-default":
      fontBgColorClass && /(tiny|small)/.test(size),
    "brad-md pl-lg pr-lg pt-xs pb-xs lh-1":
      fontBgColorClass && !/(tiny|small)/.test(size),
    [`bg-${fontBgColorClass} antialiased content-box dib`]: fontBgColorClass
  });
  const toUpperCaseClass = getClasses("", {
    uppercase: toUpperCase,
    "letter-spacing": toUpperCase && !isPill
  });

  const outerClasses = getClasses(
    `${display} ${fontSizeClass} ${fontWeightClass} ${fontStyleClass} ${fontSerifClass} ${toUpperCaseClass} ${classes} ${fontColorClass}`,
    {
      "hover-gray-dark": url && darkColors.includes(color),
      "hover-red-dark": url && color === "red",
      "label-link": url
    }
  );

  let iconComponent = null;

  const sizeToUse = isPill ? "large" : size;

  if (icon) {
    if (iconIsLogo) {
      iconComponent = getLogo({
        name: icon,
        size: sizeToUse,
        style: getIconStyle({
          iconIsLogo,
          size: sizeToUse,
          nextToPill: isPill
        })
      });
    } else {
      iconComponent = getIcon({
        name: icon,
        size: sizeToUse,
        style: getIconStyle({
          iconIsLogo,
          size: sizeToUse,
          nextToPill: isPill
        }),
        fill: isPill ? defaultTextColor : color,
        nextToPill: isPill,
        isLinked
      });
    }
  }

  const arrowComponent = showArrow
    ? getIcon({
        name: arrowIcon,
        size,
        style: {
          position: "absolute",
          top: sizeToUse === "medium" ? "0.0625rem" : "-0.0625rem"
        },
        fill: color,
        atEnd: true,
        isLinked,
        suppressHoverState: !/Chevron/.test(arrowIcon)
      })
    : null;

  // NOTE: If there is a url, need an href attibute
  // FURTHER NOTE: contentEditable attrs go here if the label is **not** a pill.
  //   If a pill, they go onto the additional span tag when there's a pill
  const outerAttrs = {
    className: `${outerClasses}`,
    ...(url ? { href: url } : {})
  };

  return React.createElement(
    url ? "a" : "span",
    outerAttrs,
    <Fragment>
      {iconComponent}
      <ConditionalWrapper
        condition={pillClasses}
        wrapper={(children) => <span className={pillClasses}>{children}</span>}
      >
        <ConditionalWrapper
          condition={innerIcon}
          wrapper={(innerChildren) => (
            <Fragment>
              {getIcon({
                name: innerIcon,
                size,
                fill: color,
                iconClasses: getClasses("", { "hover-inherit": isPill }),
                classes: "absolute top-0 left-0",
                nextToPill: false
              })}
              {innerChildren}
            </Fragment>
          )}
        >
          <EditableProperty
            tag="span"
            value={text}
            {...editablePropertyInfo}
            menuItems={editablePropertyInfo.menuItems}
          />
        </ConditionalWrapper>
      </ConditionalWrapper>
      {arrowComponent}
    </Fragment>
  );
};

Label.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.oneOf(sizes),
  style: PropTypes.oneOf(styles),
  serif: PropTypes.boolean,
  weight: PropTypes.oneOf(weights),
  display: PropTypes.oneOf(displays),
  color: PropTypes.oneOf(colors),
  backgroundColor: PropTypes.oneOf(backgroundColors),
  icon: PropTypes.oneOf(listOfValidIcons),
  innerIcon: PropTypes.oneOf(listOfValidIcons),
  showArrow: PropTypes.boolean,
  arrowIcon: PropTypes.string,
  toUpperCase: PropTypes.boolean,
  classes: PropTypes.string,
  editablePropertyInfo: PropTypes.object
};

const Labels = ({
  type = "package",
  icon = "",
  showArrow = false,
  label,
  labelSecondary,
  link
}) => {
  const {
    show = false,
    text,
    details,
    editableContentInfo,
    editablePropertyInfo
  } = label || {};
  const {
    size,
    style,
    serif,
    weight,
    color,
    backgroundColor,
    innerIcon,
    arrowIcon,
    boxed,
    toUpperCase
  } = details || {};

  // NOTE: If no primary label, don't show any label at all
  if (!show) return null;

  const {
    show: showSecondary = false,
    text: textSecondary,
    details: detailsSecondary,
    editableContentInfo: editableContentInfoSecondary,
    editablePropertyInfo: editablePropertyInfoSecondary
  } = labelSecondary || { editableContentInfo: { path: "labelSecondary" } };

  const {
    size: sizeSecondary,
    style: styleSecondary,
    serif: serifSecondary,
    weight: weightSecondary,
    display: displaySecondary,
    color: colorSecondary,
    backgroundColor: backgroundColorSecondary,
    boxed: boxedSecondary,
    toUpperCase: toUpperCaseSecondary
  } = detailsSecondary || {};

  const { url } = link || {};

  const labelsStacked = /^db$/.test(displaySecondary);
  const isPill = !!backgroundColor;
  const isPillSecondary = !!backgroundColorSecondary;

  const wrapperClasses = defaultTextColor;

  const labelClasses = getClasses("", {
    dib: isPill || (textSecondary && !labelsStacked),
    "mr-xs": textSecondary && !labelsStacked && (!boxed || !boxedSecondary),
    "lh-sm": /tiny/.test(size) && !isPill && !boxed,
    "lh-md pr-xs pl-xs bw b-solid w-fit": boxed,
    "bw-sibling": boxedSecondary && !labelsStacked
  });

  const labelSecondaryClasses = getClasses("", {
    "mt-xxs": labelsStacked && !isPill,
    "mt-xs": labelsStacked && isPill,
    "lh-sm": /tiny/.test(sizeSecondary) && !isPillSecondary && !boxedSecondary,
    "lh-md pr-xs pl-xs bw b-solid w-fit": boxedSecondary,
    "bw-l-sibling": boxed && !labelsStacked
  });

  const linkEverything = showArrow && displaySecondary === "dib";

  return (
    <div className={wrapperClasses}>
      <ConditionalWrapper
        condition={linkEverything}
        wrapper={(children) => <span className="label-inline">{children}</span>}
      >
        <Label
          url={url}
          text={text}
          type={type}
          size={size}
          style={style}
          serif={serif}
          weight={weight}
          color={color}
          backgroundColor={backgroundColor}
          toUpperCase={toUpperCase}
          icon={icon}
          innerIcon={innerIcon}
          showArrow={
            showArrow &&
            (displaySecondary !== "dib" ||
              (displaySecondary === "dib" &&
                (!showSecondary || !textSecondary)))
          }
          arrowIcon={arrowIcon}
          classes={labelClasses}
          editableContentInfo={editableContentInfo}
          editablePropertyInfo={editablePropertyInfo}
        />
        {showSecondary && (
          <Label
            url={showArrow && displaySecondary === "dib" ? url : ""}
            text={textSecondary}
            size={sizeSecondary}
            style={styleSecondary}
            serif={serifSecondary}
            weight={weightSecondary}
            display={displaySecondary}
            color={colorSecondary}
            backgroundColor={backgroundColorSecondary}
            toUpperCase={toUpperCaseSecondary}
            showArrow={showArrow && displaySecondary === "dib"}
            arrowIcon={arrowIcon}
            classes={labelSecondaryClasses}
            editableContentInfo={editableContentInfoSecondary}
            editablePropertyInfo={editablePropertyInfoSecondary}
          />
        )}
      </ConditionalWrapper>
    </div>
  );
};

Labels.propTypes = {
  type: PropTypes.string,
  showArrow: PropTypes.boolean,
  icon: PropTypes.string,
  label: PropTypes.object,
  labelSecondary: PropTypes.object,
  link: PropTypes.object
};

export const CompoundLabel = (props) => {
  const { alignment = "left", type = "package", label, form } = props;

  if (!label?.show || !label?.text) return null;

  const wrapperClasses = getClasses(`label label-${type}`, {
    "compound-label has-form pb-sm": !!form,
    [alignment]: alignment.match(/(left|center)/),
    [`${getPaddingBottomClass(type)}`]: !form
  });

  return (
    <div className={wrapperClasses}>
      <Labels {...props} />
      <Form {...form} />
    </div>
  );
};

CompoundLabel.propTypes = {
  type: PropTypes.string,
  alignment: PropTypes.string,
  label: PropTypes.object,
  form: PropTypes.object
};

export default Label;
