import { Home, ForYou, Time } from "@washingtonpost/wpds-assets/";
import { useAppContext } from "fusion-context";
import getEnv from "~/components/utilities/env";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";

const env = getEnv();

const FOR_YOU_STRATIES = {
  // NOTE: This provides a more consistent experience when you hit the back-button
  // in that when you hit the back button, you will get the same results.
  // However, the results will be less honed in that the items you click during the
  // session don't get fed back into the api.
  consistent: {
    flags: {
      isAlwaysMounted: false
    },
    query: {
      surface: "feed",
      excludeIfClicked: false,
      limit: 10,
      env
    }
  },
  // NOTE: This provides a less consistent experience when you hit the back-button
  // in that when you hit the back button, what you previously clicked will be excludded.
  // However, the results will be more honed in that the items you click during the
  // session do get fed back into the api.
  honed: {
    flags: {
      isAlwaysMounted: false
    },
    query: {
      surface: "feed",
      excludeIfClicked: true,
      limit: 10,
      env
    }
  }
};

// this should probably be revisited once we know what the data contract looks likes
export const useMultiviewTabData = () => {
  // NOTE: Get forYou from context to avoid circular dependency
  const { forYou } = useAssemblerContext();
  const ctx = useAppContext();

  forYou.strategy = FOR_YOU_STRATIES.honed;
  forYou.strategy.query = { ctx, ...forYou.strategy.query };

  const QUERIES = {
    "latest-headlines": {
      isExternalFetch: false,
      query: {
        query: "prism://prism.query/latest-news/",
        limit: 10,
        offset: 0,
        env: getEnv()
      },
      source: "prism-query"
    },
    "foryou-flex-headlines": {
      isExternalFetch: true,
      endpoint: forYou.resolve(forYou.strategy.query),
      query: forYou.strategy.query,
      fetchOptions: {}
    }
  };

  return [
    {
      icon: Home,
      name: "Top Stories",
      tabId: "top-stories",
      content: null,
      type: "curated",
      config: { isAlwaysMounted: true }
    },
    {
      icon: Time,
      name: "Latest",
      tabId: "latest",
      adStartIdx: 9,
      adLength: 3,
      adFrequency: 4,
      content: null,
      type: "feed",
      // feed query data goes in there eventually
      config: {
        isAlwaysMounted: false,
        query: QUERIES["latest-headlines"],
        getInitialQuery() {
          return QUERIES["latest-headlines"];
        },
        getNextQuery(prev) {
          return {
            ...prev,
            query: {
              ...prev.query,
              offset: prev.query.offset + prev.query.limit
            }
          };
        },
        getCount: (data) => data.count,
        transform: (data) => data,
        callback: () => true
      }
    },
    {
      icon: ForYou,
      name: "For You",
      tabId: "foryou-flex-headlines",
      adStartIdx: 12,
      adLength: 3,
      adFrequency: 4,
      content: null,
      type: "feed",
      // feed query data goes in there eventually
      config: {
        ...forYou.strategy.flags,
        query: QUERIES["foryou-flex-headlines"],
        getInitialQuery() {
          const fetchOptions = forYou.resolveOptions(
            QUERIES["foryou-flex-headlines"].query
          );
          return {
            ...QUERIES["foryou-flex-headlines"],
            fetchOptions
          };
        },
        getNextQuery(prev) {
          const fetchOptions = forYou.resolveOptions(
            QUERIES["foryou-flex-headlines"].query
          );
          return {
            ...prev,
            fetchOptions
          };
        },
        getCount: () => 30,
        transform: forYou.transform,
        callback: forYou.callback,
        handlers: forYou.handlers
      }
    }
  ];
};
