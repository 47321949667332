/* eslint-disable no-nested-ternary */
import * as React from "react";
import PropTypes from "prop-types";

function SvgThe7({ width, height, className, title, titleId }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-labelledby={titleId}
    >
      {title === undefined ? (
        <title id={titleId}>{"The 7"}</title>
      ) : title ? (
        <title id={titleId}>{title}</title>
      ) : null}
      <g clipPath="url(#clip0)">
        <path
          d="M19.8301 18.324C21.6597 16.404 22.6694 13.8176 22.6371 11.1339C22.6048 8.45016 21.533 5.88889 19.6577 4.01353C17.7823 2.13816 15.2569 1.10233 12.6371 1.13389C10.0173 1.16546 7.51759 2.26184 5.68795 4.18184L12.759 11.2529L19.8301 18.324Z"
          fill="#111111"
        />
        <path
          d="M21.1962 19.4888C20.1091 20.6296 18.8131 21.5398 17.3823 22.1673C15.9515 22.7949 14.4139 23.1276 12.8572 23.1463C11.3006 23.1651 9.75539 22.8696 8.30988 22.2766C6.86437 21.6837 5.54685 20.8051 4.43255 19.6907C3.31824 18.5764 2.42898 17.2484 1.81552 15.7823C1.20207 14.3162 0.87644 12.7409 0.857227 11.1463C0.838014 9.55169 1.12559 7.96899 1.70355 6.48858C2.2815 5.00817 3.13851 3.65903 4.22564 2.51821L12.7109 11.0035L21.1962 19.4888Z"
          fill="#266BD9"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={24.5782} height={23.993} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

SvgThe7.propTypes = {
  className: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  title: PropTypes.string,
  titleId: PropTypes.string
};
const MemoSvgThe7 = React.memo(SvgThe7);
export default MemoSvgThe7;
