import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, styled, theme } from "@washingtonpost/wpds-ui-kit";
import debounce from "lodash.debounce";
import { useAppContext } from "fusion-context";
import {
  loadingStates,
  usePaginatedContent
} from "~/spartan-homepage/fusion-modules/content";
import { useMultiView } from "~/shared-components/MultiViewContext";

import { ErrorMessage, FinishedMessage } from "./FeedMessages";
import { insertAds } from "./Feed.helpers";

const ContentWrapper = styled("div", {
  padding: "$150 $100 0"
});

// NOTE: Helps stabilize sticky nav when changing tabs and banner_top ad is present
const Waiting = styled(Box, {
  minHeight: "calc(3 * 120px)"
});

const LoadingSpinner = styled(Box, {
  width: `${theme.space["125"]}`,
  height: `${theme.space["125"]}`,
  animation: "loading 1s linear infinite",
  border: `2px solid ${theme.colors.gray80}`,
  borderTop: `2px solid transparent`,
  borderRadius: "50%",
  margin: `${theme.space["100"]} auto ${theme.space["150"]}`
});

const LoadingButton = styled(Button, {
  border: "none",
  margin: `${theme.space["050"]} auto`
});

/**
 * The main content of a feed-based Multi-view tab
 */
export const FeedContent = ({
  tabId,
  config,
  adStartIdx = 9,
  adLength = 3,
  adFrequency = 4
}) => {
  const { isReady, handleItemIntersection, hotTopics } = useMultiView();
  const [isInfiniteScrollSupported, setIsInfiniteScrollSupported] =
    useState(true);
  const { getNextQuery, getInitialQuery, getCount, transform, callback } =
    config;
  const { items, handleLoadMore, loadingState } = usePaginatedContent({
    defaultConfig: getInitialQuery(),
    getNextConfig: getNextQuery,
    getCount,
    transform,
    callback
  });
  const isLoading = loadingState === loadingStates.LOADING;
  const isSuccess = loadingState === loadingStates.SUCCESS;
  const isFinished = loadingState === loadingStates.FINISHED;
  const ref = useRef();
  const loadingRef = useRef();

  useEffect(() => {
    const element = loadingRef.current;
    if (!element) return () => {};

    const handleButtonInView = (elements) => {
      const myElement = elements[0];
      if (myElement.isIntersecting && !isLoading) {
        handleLoadMore();
      }
    };
    const onIntersection = debounce(handleButtonInView, 300);

    // if not supported, they just click "load more" like plebs
    if (
      !("IntersectionObserver" in window) &&
      !("IntersectionObserverEntry" in window) &&
      !("intersectionRatio" in window.IntersectionObserverEntry.prototype)
    ) {
      setIsInfiniteScrollSupported(false);
      return () => {};
    }

    // eslint-disable-next-line compat/compat
    const observer = new IntersectionObserver(onIntersection);
    observer.observe(element);

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [handleLoadMore, isLoading]);

  useEffect(() => {
    if (isReady) return handleItemIntersection({ ref, tabId });
    return () => {};
  }, [isReady, tabId, isSuccess, isFinished, handleItemIntersection]);

  const CONTENT = {
    ERROR: <ErrorMessage />,
    FINISHED: <FinishedMessage type={tabId} />,
    DEFAULT: (
      <Waiting>
        {isInfiniteScrollSupported ? (
          <LoadingSpinner ref={loadingRef} />
        ) : (
          <LoadingButton
            onClick={isLoading ? null : handleLoadMore}
            ref={loadingRef}
          >
            {isLoading ? "Loading" : "Load more"}
          </LoadingButton>
        )}
      </Waiting>
    )
  };

  const { globalContent } = useAppContext();
  const contentWithAds = insertAds(
    items,
    globalContent,
    adStartIdx,
    adLength,
    adFrequency
  );

  // NOTE: empty data-chain-name triggers correct itid formation
  return (
    <React.Fragment>
      {tabId === "latest" &&
        Object.entries(hotTopics)?.map(([id, component]) => {
          return <div key={id}>{component}</div>;
        })}
      <ContentWrapper data-chain-name="" ref={ref}>
        {contentWithAds?.map((element, i) => (
          <Fragment key={`${element?.props?.id}_wrapped` || i}>
            {element}
          </Fragment>
        ))}
        {CONTENT[loadingState] || CONTENT.DEFAULT}
      </ContentWrapper>
    </React.Fragment>
  );
};

FeedContent.propTypes = {
  tabId: PropTypes.string,
  adStartIdx: PropTypes.number,
  adLength: PropTypes.number,
  adFrequency: PropTypes.number,
  config: PropTypes.shape({
    isAlwaysMounted: PropTypes.bool,
    query: PropTypes.object,
    getNextQuery: PropTypes.func,
    getInitialQuery: PropTypes.func,
    getCount: PropTypes.func,
    transform: PropTypes.func,
    callback: PropTypes.func
  }),
  content: PropTypes.element
};
