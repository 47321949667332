import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { isLoggedIn } from "@washingtonpost/subs-sdk";
import {
  getUserName,
  isSubscriber,
  subscriberType,
  isAdFree
} from "../utilities/login-details";
import { sm } from "../utilities/breakpoints";
import useDetectRemovableAdsForSoftwall from "./_children/use-detect-removable-ads-for-softwall";

function onAdblockerDetected(callback) {
  let called = false;
  const data = get(window, "TWP.Data.Tracking.blockers");
  if (data && data.ad) {
    called = true;
    callback(data);
  } else {
    document.addEventListener("adblocker-detected", () => {
      called = true;
      callback(data || {});
    });
  }

  if (!called) {
    // try one last time
    setTimeout(() => {
      callback(get(window, "TWP.Data.Tracking.blockers", {}));
    }, 1000);
  }
}

const useUserDetail = () => {
  const [user, setUser] = React.useState({
    userName: "Sign in",
    isSub: isSubscriber()
  });
  React.useEffect(() => {
    const mobile = window.innerWidth < sm;
    onAdblockerDetected((data) => {
      setUser((userData) => {
        return {
          ...(userData || {}),
          hasAdBlocker: data.ad
        };
      });
    });
    const isAuthed = isLoggedIn({});
    if (isAuthed) {
      setUser((userData) => {
        return {
          ...(userData || {}),
          userName: getUserName(),
          isSub: isSubscriber(),
          isAdFreeSub: isAdFree(),
          isAuthenticated: isAuthed,
          subscriberType: subscriberType(),
          // TODO: useDevice hook?
          mobile
        };
      });
    } else {
      setUser((userData) => {
        return {
          ...(userData || {}),
          isAdFreeSub: isAdFree(),
          isSub: isSubscriber(),
          isAuthenticated: false
        };
      });
    }
  }, []);
  return user;
};

const useUserData = () => {
  const userDetails = useUserDetail();
  const removableAds = useDetectRemovableAdsForSoftwall();
  const [showFullArticleContent, setShowFullArticleContent] =
    React.useState(true);

  React.useEffect(() => {
    if (!window.registerPwapiConsumer) return;

    window.registerPwapiConsumer((data) => {
      const showFullContent = [3, 8].indexOf(data.action) === -1;
      setShowFullArticleContent(showFullContent);
    });
  }, []);
  return {
    ...userDetails,
    showFullArticleContent,
    removableAds
  };
};

const UserContext = React.createContext({});
const { Consumer: UserConsumer } = UserContext;

export const UserProvider = ({ children }) => {
  const value = useUserData();
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node
};

export const useUser = () => {
  return React.useContext(UserContext);
};

export { UserConsumer };
