import React from "react";
import PropTypes from "prop-types";
import ElectionDefault from "@washingtonpost/wpds-assets/asset/election-default";
import OlympicsLogo from "@washingtonpost/wpds-assets/asset/olympics";
import WorldCup from "@washingtonpost/wpds-assets/asset/qatar-world-cup";
import { The7Logo } from "../../../components/core/logos/the7";
import { ICON_SIZES } from "./Icons";

const sizes = {
  tiny: 14,
  small: 14,
  medium: 18,
  normal: 22,
  large: 22,
  "x-large": 24
};

// NOTE: Should match one of the well-behaved icons
const width = ICON_SIZES;

export const logos = {
  "Election Icon": {
    component: ElectionDefault,
    size: sizes,
    width
  },
  Olympics: {
    component: OlympicsLogo,
    size: sizes,
    width
  },
  "World Cup": {
    component: WorldCup,
    size: sizes,
    width
  },
  "The 7": {
    component: The7Logo,
    size: sizes,
    width
  }
};

export const getLogo = ({ name, size, classes, style }) => {
  const logo = logos[name];
  if (!logo) return null;
  const Logo = logos[name].component;
  if (!Logo) return null;

  const logoSize = `${logo.size[size]}px`;
  const marginFactor = size === "medium" ? 1.5 : 1.25;
  const wrapperStyle = {
    marginRight: `${Math.round(marginFactor * logo.width[size])}px`
  };

  return (
    <span className="relative" style={wrapperStyle}>
      <span className={classes} style={style}>
        <Logo height={logoSize} width={logoSize} />
      </span>
    </span>
  );
};

getLogo.propTypes = {
  name: PropTypes.any,
  size: PropTypes.string,
  classes: PropTypes.object,
  style: PropTypes.object
};
