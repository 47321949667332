import React from "react";
import PropTypes from "prop-types";

const Headline = (props) => {
  const {
    className = "",
    videoData = {},
    isAdmin,
    displayWithoutVideo
  } = props;
  const { headline, headlinePrefix, url, videoLabel, count } = videoData;
  const noVideo = displayWithoutVideo ? "no-video" : "";
  return (
    <div
      className={`live-headline ${noVideo} ${className} inline-flex self-center`}
    >
      <a className="white pointer" href={url} data-link-detail={count}>
        <strong className="bold">{headlinePrefix}</strong>
        {headline}
      </a>
      {isAdmin === true && (
        <span className="light italic pl-xs red">[{videoLabel}]</span>
      )}
    </div>
  );
};

Headline.propTypes = {
  className: PropTypes.string,
  displayWithoutVideo: PropTypes.bool,
  isAdmin: PropTypes.bool,
  videoData: PropTypes.object
};

export default Headline;
