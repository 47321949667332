import PropTypes from "prop-types";
import defaults from "../components/chains/top-table/utilities/custom-field-defaults";
import {
  HPLayouts,
  dividerWeights,
  verticalDividerWeights,
  labelAlignments,
  labelIcons,
  labelTypes,
  ctaLabelTypes
} from "../components/chains/top-table/utilities/settings";
import { HPSeparators } from "../components/chains/top-table/utilities/separator-helper";

export const TopTablePropTypes = {
  name: PropTypes.string,
  children: PropTypes.array.isRequired,
  childProps: PropTypes.array.isRequired,
  context: PropTypes.shape({
    isAdmin: PropTypes.boolean
  }),
  className: PropTypes.string,
  customFields: PropTypes.shape({
    displayName: PropTypes.string.tag({
      name: "Display Name",
      defaultValue: defaults.displayName
    }),
    layout: PropTypes.oneOf(HPLayouts).tag({
      name: "HP Layout",
      defaultValue: defaults.layout
    }),
    suppressDuplicates: PropTypes.bool.tag({
      name: "De-dupe",
      description:
        "If true, contents of this chain will be suppressed if they appear on the page already.",
      defaultValue: defaults.suppressDuplicates,
      hidden: true
    }),
    hideValidationMsg: PropTypes.bool.tag({
      name: "Hide Validation Messages?",
      description:
        "When checked the admin only validation messages will be hidden.",
      defaultValue: defaults.hideValidationMsg,
      hidden: true
    }),
    hideHelperLabels: PropTypes.bool.tag({
      name: "Hide Helpers",
      description:
        "When checked the table/column and feature id helper labels will be hidden and guide colors removed",
      defaultValue: defaults.hideHelperLabels,
      hidden: true
    }),
    hideFromDesktop: PropTypes.boolean.tag({
      name: "Hide From Desktop",
      description:
        "When checked this chain will not be included at breakpoints where this chain shows desktop presets.",
      defaultValue: defaults.hideFromDesktop
    }),
    hideFromMobile: PropTypes.boolean.tag({
      name: "Hide From Mobile",
      description:
        "When checked this chain will not be included at breakpoints where this chain shows mobile presets.",
      defaultValue: defaults.hideFromMobile
    }),
    hideFromWeb: PropTypes.boolean.tag({
      name: "Hide From Web",
      description: "When checked this chain will not be included on the web.",
      defaultValue: defaults.hideFromWeb
    }),
    hideFromApps: PropTypes.boolean.tag({
      name: "Hide From Apps",
      description: "When checked this chain will not be included in apps.",
      defaultValue: defaults.hideFromApps
    }),
    disableLazyLoading: PropTypes.boolean.tag({
      name: "Disable Lazy Loading",
      description:
        "This should only be enabled when the chain is above the fold",
      defaultValue: defaults.disableLazyLoading
    }),
    personalizedOrdering: PropTypes.boolean.tag({
      name: "Personalized Ordering?",
      description:
        "When checked this chain will be included in personalized ordering if it also has a topic mapping.",
      defaultValue: defaults.personalizedOrdering
    }),
    mappedTopicName: PropTypes.string.tag({
      name: "Topic Mapping",
      description:
        "A comma-separated list of topic names to map this chain to, such as 'sports' or 'politics,national,national-security'. More than one chain may have the same topic mapping and their relative ordering will be preserved",
      defaultValue: defaults.mappedTopicName
    }),
    bottomSeparator: PropTypes.oneOf(Object.keys(HPSeparators)).tag({
      labels: HPSeparators,
      group: "DECORATIONS",
      name: "Bottom separator",
      description: "Select the separator to appear below the chain.",
      defaultValue: defaults.bottomSeparator
    }),
    includeDividers: PropTypes.bool.tag({
      group: "DECORATIONS",
      name: "Include dividers",
      description:
        "Select whether to include horizontal and vertical dividers separating tables, columns and features.",
      defaultValue: defaults.includeDividers
    }),
    // CHAIN
    // labelShow
    labelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "CHAIN LABEL",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // labelType
    labelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "CHAIN LABEL",
      name: "Type",
      description: "Select the look of the the label."
    }),
    // label
    label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "CHAIN LABEL",
      name: "Label text",
      description: "The label text. Optional."
    }),
    // labelUrl
    labelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "CHAIN LABEL",
      name: "Label link",
      description: "The label link. Optional. No default value."
    }),
    // labelSecondaryShow
    labelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "CHAIN LABEL",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // labelSecondary
    labelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "CHAIN LABEL",
      name: "Secondary label",
      description: "The secondary label text. Optional."
    }),
    // labelAlignment
    labelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "CHAIN LABEL",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'."
    }),
    // labelIcon
    labelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "CHAIN LABEL",
      name: "Select icon to prepend",
      description: "The label icon to prepend to the label. Defaults to 'None'."
    }),
    // formShow
    formShow: PropTypes.bool.tag({
      defaultValue: defaults.formShow,
      group: "CHAIN SEARCH BOX",
      name: "Show search box?",
      description: "If checked, display search box (default false)."
    }),
    // formMethod: Not implementing. hard-coded to "GET" until "POST" is needed
    // formAction
    formAction: PropTypes.string.tag({
      defaultValue: defaults.formAction,
      group: "CHAIN SEARCH BOX",
      name: "Url",
      description:
        "Enter the search url, e.g. 'https://www.washingtonpost.com/recipes/'"
    }),
    // formType: Not implementing. hard-coded to "search" until other type is needed
    // formParam
    formParam: PropTypes.string.tag({
      defaultValue: defaults.formParam,
      group: "CHAIN SEARCH BOX",
      name: "Url param",
      description: "Enter the search param, e.g. 'q'"
    }),
    // formPlaceholder
    formPlaceholder: PropTypes.string.tag({
      defaultValue: defaults.formPlaceholder,
      group: "CHAIN SEARCH BOX",
      name: "Placeholder text",
      description: "Enter the placeholder text (e.g. 'Search for a recipe')."
    }),
    // linkGroup
    linkGroup: PropTypes.string.tag({
      defaultValue: defaults.linkGroup,
      group: "CHAIN LABEL",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking"
    }),
    // TABLE 0 (aka TOP STRIP)
    // table0HorizontalDividerWeight
    table0HorizontalDividerWeight: PropTypes.oneOf(
      Object.keys(dividerWeights)
    ).tag({
      labels: dividerWeights,
      defaultValue: defaults.table0HorizontalDividerWeight,
      group: "TOP STRIP",
      name: "Horizontal divider",
      description:
        "Horizontal dividers appear below the table only when there is another table positioned directly below it. Additionally, if this option and the vertical divider have the same value (Light, Dark, None), then, this divider will be applied to all features within the table."
    }),
    // table0VerticalDividerWeight
    table0VerticalDividerWeight: PropTypes.oneOf(
      Object.keys(verticalDividerWeights)
    ).tag({
      labels: verticalDividerWeights,
      defaultValue: defaults.table0VerticalDividerWeight,
      group: "TOP STRIP",
      name: "Vertical divider",
      description:
        "Vertical dividers only appear to the left of features within the table. This option is only active if it has the same value (Light, Dark, None) as the horizontal divider."
    }),
    // table0LabelShow
    table0LabelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "TOP STRIP",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table0LabelType
    table0LabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "TOP STRIP",
      name: "Type",
      description: "Select the look of the the label."
    }),
    // table0Label
    table0Label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "TOP STRIP",
      name: "Label text",
      description: "The label text. Optional."
    }),
    // table0LabelUrl
    table0LabelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "TOP STRIP",
      name: "Label link",
      description: "The label link. Optional. No default value."
    }),
    // table0LabelSecondaryShow
    table0LabelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "TOP STRIP",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table0LabelSecondary
    table0LabelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "TOP STRIP",
      name: "Secondary label",
      description: "The secondary label text. Optional."
    }),
    // table0LabelAlignment
    table0LabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "TOP STRIP",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'."
    }),
    // table0LabelIcon
    table0LabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "TOP STRIP",
      name: "Select icon to prepend",
      description: "The label icon to prepend to the label. Defaults to 'None'."
    }),
    // table0FormShow
    table0FormShow: PropTypes.bool.tag({
      defaultValue: defaults.formShow,
      group: "TOP STRIP",
      name: "Show search box?",
      description: "If checked, display search box (default false)."
    }),
    // table0FormMethod: Not implementing. hard-coded to "GET" until "POST" is needed
    // table0FormAction
    table0FormAction: PropTypes.string.tag({
      defaultValue: defaults.formAction,
      group: "TOP STRIP",
      name: "Url",
      description:
        "Enter the search url, e.g. 'https://www.washingtonpost.com/recipes/'"
    }),
    // table0FormType: Not implementing. hard-coded to "search" until other type is needed
    // table0FormParam
    table0FormParam: PropTypes.string.tag({
      defaultValue: defaults.formParam,
      group: "TOP STRIP",
      name: "Url param",
      description: "Enter the search param, e.g. 'q'"
    }),
    // table0FormPlaceholder
    table0FormPlaceholder: PropTypes.string.tag({
      defaultValue: defaults.formPlaceholder,
      group: "TOP STRIP",
      name: "Placeholder text",
      description: "Enter the placeholder text (e.g. 'Search for a recipe')."
    }),
    // table0CtaLabelShow
    table0CtaLabelShow: PropTypes.bool.tag({
      defaultValue: defaults.ctaLabelShow,
      group: "TOP STRIP",
      name: "Show CTA?",
      description: "If checked, display CTA (default false)."
    }),
    // table0CtaLabel
    table0CtaLabel: PropTypes.string.tag({
      defaultValue: defaults.ctaLabel,
      group: "TOP STRIP",
      name: "CTA text",
      description: "The CTA text. Optional."
    }),
    // table0CtaLabelUrl
    table0CtaLabelUrl: PropTypes.string.tag({
      defaultValue: defaults.ctaLabelUrl,
      group: "TOP STRIP",
      name: "CTA link",
      description:
        "The CTA link. Required but falls back to label link if label is due to show."
    }),
    // table0CtaLabelType
    table0CtaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      defaultValue: defaults.ctaLabelType,
      group: "TOP STRIP",
      name: "CTA Style",
      description: "Select the look of the the CTA.",
      hidden: false
    }),
    // table0LinkGroup
    table0LinkGroup: PropTypes.string.tag({
      defaultValue: defaults.table0LinkGroup,
      group: "TOP STRIP",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking"
    }),
    // TABLE 1
    // table1HorizontalDividerWeight
    table1HorizontalDividerWeight: PropTypes.oneOf(
      Object.keys(dividerWeights)
    ).tag({
      labels: dividerWeights,
      defaultValue: defaults.table1HorizontalDividerWeight,
      group: "TABLE 1",
      name: "Horizontal divider",
      description:
        "Horizontal dividers appear below the table only when there is another table positioned directly below it. Additionally, if this option and the vertical divider have the same value (Light, Dark, None), then, this divider will be applied to all features within the table."
    }),
    // table1VerticalDividerWeight
    table1VerticalDividerWeight: PropTypes.oneOf(
      Object.keys(verticalDividerWeights)
    ).tag({
      labels: verticalDividerWeights,
      defaultValue: defaults.table1VerticalDividerWeight,
      group: "TABLE 1",
      name: "Vertical divider",
      description:
        "Vertical dividers only appear to the left of features within the table. This option is only active if it has the same value (Light, Dark, None) as the horizontal divider."
    }),
    // table1LabelShow
    table1LabelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "TABLE 1",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table1LabelType
    table1LabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "TABLE 1",
      name: "Type",
      description: "Select the look of the the label."
    }),
    // table1Label
    table1Label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "TABLE 1",
      name: "Label text",
      description: "The label text. Optional."
    }),
    // table1LabelUrl
    table1LabelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "TABLE 1",
      name: "Label link",
      description: "The label link. Optional. No default value."
    }),
    // table1LabelSecondaryShow
    table1LabelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "TABLE 1",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table1LabelSecondary
    table1LabelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "TABLE 1",
      name: "Secondary label",
      description: "The secondary label text. Optional."
    }),
    // table1LabelAlignment
    table1LabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "TABLE 1",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'."
    }),
    // table1LabelIcon
    table1LabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "TABLE 1",
      name: "Select icon to prepend",
      description: "The label icon to prepend to the label. Defaults to 'None'."
    }),
    // table1FormShow
    table1FormShow: PropTypes.bool.tag({
      defaultValue: defaults.formShow,
      group: "TABLE 1",
      name: "Show search box?",
      description: "If checked, display search box (default false)."
    }),
    // table1FormMethod: Not implementing. hard-coded to "GET" until "POST" is needed
    // table1FormAction
    table1FormAction: PropTypes.string.tag({
      defaultValue: defaults.formAction,
      group: "TABLE 1",
      name: "Url",
      description:
        "Enter the search url, e.g. 'https://www.washingtonpost.com/recipes/'"
    }),
    // table1FormType: Not implementing. hard-coded to "search" until other type is needed
    // table1FormParam
    table1FormParam: PropTypes.string.tag({
      defaultValue: defaults.formParam,
      group: "TABLE 1",
      name: "Url param",
      description: "Enter the search param, e.g. 'q'"
    }),
    // table1FormPlaceholder
    table1FormPlaceholder: PropTypes.string.tag({
      defaultValue: defaults.formPlaceholder,
      group: "TABLE 1",
      name: "Placeholder text",
      description: "Enter the placeholder text (e.g. 'Search for a recipe')."
    }),
    // table1CtaLabelShow
    table1CtaLabelShow: PropTypes.bool.tag({
      defaultValue: defaults.ctaLabelShow,
      group: "TABLE 1",
      name: "Show CTA?",
      description: "If checked, display CTA (default false)."
    }),
    // table1CtaLabel
    table1CtaLabel: PropTypes.string.tag({
      defaultValue: defaults.ctaLabel,
      group: "TABLE 1",
      name: "CTA text",
      description: "The CTA text. Optional."
    }),
    // table1CtaLabelUrl
    table1CtaLabelUrl: PropTypes.string.tag({
      defaultValue: defaults.ctaLabelUrl,
      group: "TABLE 1",
      name: "CTA link",
      description:
        "The CTA link. Required but falls back to label link if label is due to show."
    }),
    // table1CtaLabelType
    table1CtaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      defaultValue: defaults.ctaLabelType,
      group: "TABLE 1",
      name: "CTA Style",
      description: "Select the look of the the CTA.",
      hidden: false
    }),
    // table1LinkGroup
    table1LinkGroup: PropTypes.string.tag({
      defaultValue: defaults.table1LinkGroup,
      group: "TABLE 1",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking"
    }),
    // TABLE 2
    // table2HorizontalDividerWeight
    table2HorizontalDividerWeight: PropTypes.oneOf(
      Object.keys(dividerWeights)
    ).tag({
      labels: dividerWeights,
      defaultValue: defaults.table2HorizontalDividerWeight,
      group: "TABLE 2",
      name: "Horizontal divider",
      description:
        "Horizontal dividers appear below the table only when there is another table positioned directly below it. Additionally, if this option and the vertical divider have the same value (Light, Dark, None), then, this divider will be applied to all features within the table."
    }),
    // table2VerticalDividerWeight
    table2VerticalDividerWeight: PropTypes.oneOf(
      Object.keys(verticalDividerWeights)
    ).tag({
      labels: verticalDividerWeights,
      defaultValue: defaults.table2VerticalDividerWeight,
      group: "TABLE 2",
      name: "Vertical divider",
      description:
        "Vertical dividers only appear to the left of features within the table. This option is only active if it has the same value (Light, Dark, None) as the horizontal divider."
    }),
    // table2LabelShow
    table2LabelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "TABLE 2",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table2LabelType
    table2LabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "TABLE 2",
      name: "Type",
      description: "Select the look of the the label."
    }),
    // table2Label
    table2Label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "TABLE 2",
      name: "Label text",
      description: "The label text. Optional."
    }),
    // table2LabelUrl
    table2LabelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "TABLE 2",
      name: "Label link",
      description: "The label link. Optional. No default value."
    }),
    // table2LabelSecondaryShow
    table2LabelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "TABLE 2",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table2LabelSecondary
    table2LabelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "TABLE 2",
      name: "Secondary label",
      description: "The secondary label text. Optional."
    }),
    // table2LabelAlignment
    table2LabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "TABLE 2",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'."
    }),
    // table2LabelIcon
    table2LabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "TABLE 2",
      name: "Select icon to prepend",
      description: "The label icon to prepend to the label. Defaults to 'None'."
    }),
    // table2FormShow
    table2FormShow: PropTypes.bool.tag({
      defaultValue: defaults.formShow,
      group: "TABLE 2",
      name: "Show search box?",
      description: "If checked, display search box (default false)."
    }),
    // table2FormMethod: Not implementing. hard-coded to "GET" until "POST" is needed
    // table2FormAction
    table2FormAction: PropTypes.string.tag({
      defaultValue: defaults.formAction,
      group: "TABLE 2",
      name: "Url",
      description:
        "Enter the search url, e.g. 'https://www.washingtonpost.com/recipes/'"
    }),
    // table2FormType: Not implementing. hard-coded to "search" until other type is needed
    // table2FormParam
    table2FormParam: PropTypes.string.tag({
      defaultValue: defaults.formParam,
      group: "TABLE 2",
      name: "Url param",
      description: "Enter the search param, e.g. 'q'"
    }),
    // table2FormPlaceholder
    table2FormPlaceholder: PropTypes.string.tag({
      defaultValue: defaults.formPlaceholder,
      group: "TABLE 2",
      name: "Placeholder text",
      description: "Enter the placeholder text (e.g. 'Search for a recipe')."
    }),
    // table2CtaLabelShow
    table2CtaLabelShow: PropTypes.bool.tag({
      defaultValue: defaults.ctaLabelShow,
      group: "TABLE 2",
      name: "Show CTA?",
      description: "If checked, display CTA (default false)."
    }),
    // table2CtaLabel
    table2CtaLabel: PropTypes.string.tag({
      defaultValue: defaults.ctaLabel,
      group: "TABLE 2",
      name: "CTA text",
      description: "The CTA text. Optional."
    }),
    // table2CtaLabelUrl
    table2CtaLabelUrl: PropTypes.string.tag({
      defaultValue: defaults.ctaLabelUrl,
      group: "TABLE 2",
      name: "CTA link",
      description:
        "The CTA link. Required but falls back to label link if label is due to show."
    }),
    // table2CtaLabelType
    table2CtaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      defaultValue: defaults.ctaLabelType,
      group: "TABLE 2",
      name: "CTA Style",
      description: "Select the look of the the CTA.",
      hidden: false
    }),
    // table2LinkGroup
    table2LinkGroup: PropTypes.string.tag({
      defaultValue: defaults.table2LinkGroup,
      group: "TABLE 2",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking"
    }),
    // TABLE 3
    // table3HorizontalDividerWeight
    table3HorizontalDividerWeight: PropTypes.oneOf(
      Object.keys(dividerWeights)
    ).tag({
      labels: dividerWeights,
      defaultValue: defaults.table3HorizontalDividerWeight,
      group: "TABLE 3",
      name: "Horizontal divider",
      description:
        "Horizontal dividers appear below the table only when there is another table positioned directly below it. Additionally, if this option and the vertical divider have the same value (Light, Dark, None), then, this divider will be applied to all features within the table."
    }),
    // table3VerticalDividerWeight
    table3VerticalDividerWeight: PropTypes.oneOf(
      Object.keys(verticalDividerWeights)
    ).tag({
      labels: verticalDividerWeights,
      defaultValue: defaults.table3VerticalDividerWeight,
      group: "TABLE 3",
      name: "Vertical divider",
      description:
        "Vertical dividers only appear to the left of features within the table. This option is only active if it has the same value (Light, Dark, None) as the horizontal divider."
    }),
    // table3LabelShow
    table3LabelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "TABLE 3",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table3LabelType
    table3LabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "TABLE 3",
      name: "Type",
      description: "Select the look of the the label."
    }),
    // table3Label
    table3Label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "TABLE 3",
      name: "Label text",
      description: "The label text. Optional."
    }),
    // table3LabelUrl
    table3LabelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "TABLE 3",
      name: "Label link",
      description: "The label link. Optional. No default value."
    }),
    // table3LabelSecondaryShow
    table3LabelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "TABLE 3",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table3LabelSecondary
    table3LabelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "TABLE 3",
      name: "Secondary label",
      description: "The secondary label text. Optional."
    }),
    // table3LabelAlignment
    table3LabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "TABLE 3",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'."
    }),
    // table3LabelIcon
    table3LabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "TABLE 3",
      name: "Select icon to prepend",
      description: "The label icon to prepend to the label. Defaults to 'None'."
    }),
    // table3FormShow
    table3FormShow: PropTypes.bool.tag({
      defaultValue: defaults.formShow,
      group: "TABLE 3",
      name: "Show search box?",
      description: "If checked, display search box (default false)."
    }),
    // table3FormMethod: Not implementing. hard-coded to "GET" until "POST" is needed
    // table3FormAction
    table3FormAction: PropTypes.string.tag({
      defaultValue: defaults.formAction,
      group: "TABLE 3",
      name: "Url",
      description:
        "Enter the search url, e.g. 'https://www.washingtonpost.com/recipes/'"
    }),
    // table3FormType: Not implementing. hard-coded to "search" until other type is needed
    // table3FormParam
    table3FormParam: PropTypes.string.tag({
      defaultValue: defaults.formParam,
      group: "TABLE 3",
      name: "Url param",
      description: "Enter the search param, e.g. 'q'"
    }),
    // table3FormPlaceholder
    table3FormPlaceholder: PropTypes.string.tag({
      defaultValue: defaults.formPlaceholder,
      group: "TABLE 3",
      name: "Placeholder text",
      description: "Enter the placeholder text (e.g. 'Search for a recipe')."
    }),
    // table3CtaLabelShow
    table3CtaLabelShow: PropTypes.bool.tag({
      defaultValue: defaults.ctaLabelShow,
      group: "TABLE 3",
      name: "Show CTA?",
      description: "If checked, display CTA (default false)."
    }),
    // table3CtaLabel
    table3CtaLabel: PropTypes.string.tag({
      defaultValue: defaults.ctaLabel,
      group: "TABLE 3",
      name: "CTA text",
      description: "The CTA text. Optional."
    }),
    // table3CtaLabelUrl
    table3CtaLabelUrl: PropTypes.string.tag({
      defaultValue: defaults.ctaLabelUrl,
      group: "TABLE 3",
      name: "CTA link",
      description:
        "The CTA link. Required but falls back to label link if label is due to show."
    }),
    // table3CtaLabelType
    table3CtaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      defaultValue: defaults.ctaLabelType,
      group: "TABLE 3",
      name: "CTA Style",
      description: "Select the look of the the CTA.",
      hidden: false
    }),
    // table3LinkGroup
    table3LinkGroup: PropTypes.string.tag({
      defaultValue: defaults.table3LinkGroup,
      group: "TABLE 3",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking"
    }),
    // TABLE 4
    // table4HorizontalDividerWeight
    table4HorizontalDividerWeight: PropTypes.oneOf(
      Object.keys(dividerWeights)
    ).tag({
      labels: dividerWeights,
      defaultValue: defaults.table4HorizontalDividerWeight,
      group: "TABLE 4",
      name: "Horizontal divider",
      description:
        "Horizontal dividers appear below the table only when there is another table positioned directly below it. Additionally, if this option and the vertical divider have the same value (Light, Dark, None), then, this divider will be applied to all features within the table."
    }),
    // table4VerticalDividerWeight
    table4VerticalDividerWeight: PropTypes.oneOf(
      Object.keys(verticalDividerWeights)
    ).tag({
      labels: verticalDividerWeights,
      defaultValue: defaults.table4VerticalDividerWeight,
      group: "TABLE 4",
      name: "Vertical divider",
      description:
        "Vertical dividers only appear to the left of features within the table. This option is only active if it has the same value (Light, Dark, None) as the horizontal divider."
    }),
    // table4LabelShow
    table4LabelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "TABLE 4",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table4LabelType
    table4LabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "TABLE 4",
      name: "Type",
      description: "Select the look of the the label."
    }),
    // table4Label
    table4Label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "TABLE 4",
      name: "Label text",
      description: "The label text. Optional."
    }),
    // table4LabelUrl
    table4LabelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "TABLE 4",
      name: "Label link",
      description: "The label link. Optional. No default value."
    }),
    // table4LabelSecondaryShow
    table4LabelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "TABLE 4",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table4LabelSecondary
    table4LabelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "TABLE 4",
      name: "Secondary label",
      description: "The secondary label text. Optional."
    }),
    // table4LabelAlignment
    table4LabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "TABLE 4",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'."
    }),
    // table4LabelIcon
    table4LabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "TABLE 4",
      name: "Select icon to prepend",
      description: "The label icon to prepend to the label. Defaults to 'None'."
    }),
    // table4FormShow
    table4FormShow: PropTypes.bool.tag({
      defaultValue: defaults.formShow,
      group: "TABLE 4",
      name: "Show search box?",
      description: "If checked, display search box (default false)."
    }),
    // table4FormMethod: Not implementing. hard-coded to "GET" until "POST" is needed
    // table4FormAction
    table4FormAction: PropTypes.string.tag({
      defaultValue: defaults.formAction,
      group: "TABLE 4",
      name: "Url",
      description:
        "Enter the search url, e.g. 'https://www.washingtonpost.com/recipes/'"
    }),
    // table4FormType: Not implementing. hard-coded to "search" until other type is needed
    // table4FormParam
    table4FormParam: PropTypes.string.tag({
      defaultValue: defaults.formParam,
      group: "TABLE 4",
      name: "Url param",
      description: "Enter the search param, e.g. 'q'"
    }),
    // table4FormPlaceholder
    table4FormPlaceholder: PropTypes.string.tag({
      defaultValue: defaults.formPlaceholder,
      group: "TABLE 4",
      name: "Placeholder text",
      description: "Enter the placeholder text (e.g. 'Search for a recipe')."
    }),
    // table4CtaLabelShow
    table4CtaLabelShow: PropTypes.bool.tag({
      defaultValue: defaults.ctaLabelShow,
      group: "TABLE 4",
      name: "Show CTA?",
      description: "If checked, display CTA (default false)."
    }),
    // table4CtaLabel
    table4CtaLabel: PropTypes.string.tag({
      defaultValue: defaults.ctaLabel,
      group: "TABLE 4",
      name: "CTA text",
      description: "The CTA text. Optional."
    }),
    // table4CtaLabelUrl
    table4CtaLabelUrl: PropTypes.string.tag({
      defaultValue: defaults.ctaLabelUrl,
      group: "TABLE 4",
      name: "CTA link",
      description:
        "The CTA link. Required but falls back to label link if label is due to show."
    }),
    // table4CtaLabelType
    table4CtaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      defaultValue: defaults.ctaLabelType,
      group: "TABLE 4",
      name: "CTA Style",
      description: "Select the look of the the CTA.",
      hidden: false
    }),
    // table4LinkGroup
    table4LinkGroup: PropTypes.string.tag({
      defaultValue: defaults.table4LinkGroup,
      group: "TABLE 4",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking"
    }),
    // TABLE 5
    // table5HorizontalDividerWeight
    table5HorizontalDividerWeight: PropTypes.oneOf(
      Object.keys(dividerWeights)
    ).tag({
      labels: dividerWeights,
      defaultValue: defaults.table5HorizontalDividerWeight,
      group: "TABLE 5",
      name: "Horizontal divider",
      description:
        "Horizontal dividers appear below the table only when there is another table positioned directly below it. Additionally, if this option and the vertical divider have the same value (Light, Dark, None), then, this divider will be applied to all features within the table."
    }),
    // table5VerticalDividerWeight
    table5VerticalDividerWeight: PropTypes.oneOf(
      Object.keys(verticalDividerWeights)
    ).tag({
      labels: verticalDividerWeights,
      defaultValue: defaults.table5VerticalDividerWeight,
      group: "TABLE 5",
      name: "Vertical divider",
      description:
        "Vertical dividers only appear to the left of features within the table. This option is only active if it has the same value (Light, Dark, None) as the horizontal divider."
    }),
    // table5LabelShow
    table5LabelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "TABLE 5",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table5LabelType
    table5LabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "TABLE 5",
      name: "Type",
      description: "Select the look of the the label."
    }),
    // table5Label
    table5Label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "TABLE 5",
      name: "Label text",
      description: "The label text. Optional."
    }),
    // table5LabelUrl
    table5LabelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "TABLE 5",
      name: "Label link",
      description: "The label link. Optional. No default value."
    }),
    // table5LabelSecondaryShow
    table5LabelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "TABLE 5",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table5LabelSecondary
    table5LabelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "TABLE 5",
      name: "Secondary label",
      description: "The secondary label text. Optional."
    }),
    // table5LabelAlignment
    table5LabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "TABLE 5",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'."
    }),
    // table5LabelIcon
    table5LabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "TABLE 5",
      name: "Select icon to prepend",
      description: "The label icon to prepend to the label. Defaults to 'None'."
    }),
    // table5FormShow
    table5FormShow: PropTypes.bool.tag({
      defaultValue: defaults.formShow,
      group: "TABLE 5",
      name: "Show search box?",
      description: "If checked, display search box (default false)."
    }),
    // table5FormMethod: Not implementing. hard-coded to "GET" until "POST" is needed
    // table5FormAction
    table5FormAction: PropTypes.string.tag({
      defaultValue: defaults.formAction,
      group: "TABLE 5",
      name: "Url",
      description:
        "Enter the search url, e.g. 'https://www.washingtonpost.com/recipes/'"
    }),
    // table5FormType: Not implementing. hard-coded to "search" until other type is needed
    // table5FormParam
    table5FormParam: PropTypes.string.tag({
      defaultValue: defaults.formParam,
      group: "TABLE 5",
      name: "Url param",
      description: "Enter the search param, e.g. 'q'"
    }),
    // table5FormPlaceholder
    table5FormPlaceholder: PropTypes.string.tag({
      defaultValue: defaults.formPlaceholder,
      group: "TABLE 5",
      name: "Placeholder text",
      description: "Enter the placeholder text (e.g. 'Search for a recipe')."
    }),
    // table5CtaLabelShow
    table5CtaLabelShow: PropTypes.bool.tag({
      defaultValue: defaults.ctaLabelShow,
      group: "TABLE 5",
      name: "Show CTA?",
      description: "If checked, display CTA (default false)."
    }),
    // table5CtaLabel
    table5CtaLabel: PropTypes.string.tag({
      defaultValue: defaults.ctaLabel,
      group: "TABLE 5",
      name: "CTA text",
      description: "The CTA text. Optional."
    }),
    // table5CtaLabelUrl
    table5CtaLabelUrl: PropTypes.string.tag({
      defaultValue: defaults.ctaLabelUrl,
      group: "TABLE 5",
      name: "CTA link",
      description:
        "The CTA link. Required but falls back to label link if label is due to show."
    }),
    // table5CtaLabelType
    table5CtaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      defaultValue: defaults.ctaLabelType,
      group: "TABLE 5",
      name: "CTA Style",
      description: "Select the look of the the CTA.",
      hidden: false
    }),
    // table5LinkGroup
    table5LinkGroup: PropTypes.string.tag({
      defaultValue: defaults.table5LinkGroup,
      group: "TABLE 5",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking"
    }),
    // TABLE 6
    // table6HorizontalDividerWeight
    table6HorizontalDividerWeight: PropTypes.oneOf(
      Object.keys(dividerWeights)
    ).tag({
      labels: dividerWeights,
      defaultValue: defaults.table6HorizontalDividerWeight,
      group: "TABLE 6",
      name: "Horizontal divider",
      description:
        "Horizontal dividers appear below the table only when there is another table positioned directly below it. Additionally, if this option and the vertical divider have the same value (Light, Dark, None), then, this divider will be applied to all features within the table."
    }),
    // table6VerticalDividerWeight
    table6VerticalDividerWeight: PropTypes.oneOf(
      Object.keys(verticalDividerWeights)
    ).tag({
      labels: verticalDividerWeights,
      defaultValue: defaults.table6VerticalDividerWeight,
      group: "TABLE 6",
      name: "Vertical divider",
      description:
        "Vertical dividers only appear to the left of features within the table. This option is only active if it has the same value (Light, Dark, None) as the horizontal divider."
    }),
    // table6LabelShow
    table6LabelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "TABLE 6",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table6LabelType
    table6LabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "TABLE 6",
      name: "Type",
      description: "Select the look of the the label."
    }),
    // table6Label
    table6Label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "TABLE 6",
      name: "Label text",
      description: "The label text. Optional."
    }),
    // table6LabelUrl
    table6LabelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "TABLE 6",
      name: "Label link",
      description: "The label link. Optional. No default value."
    }),
    // table6LabelSecondaryShow
    table6LabelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "TABLE 6",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table6LabelSecondary
    table6LabelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "TABLE 6",
      name: "Secondary label",
      description: "The secondary label text. Optional."
    }),
    // table6LabelAlignment
    table6LabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "TABLE 6",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'."
    }),
    // table6LabelIcon
    table6LabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "TABLE 6",
      name: "Select icon to prepend",
      description: "The label icon to prepend to the label. Defaults to 'None'."
    }),
    // table6FormShow
    table6FormShow: PropTypes.bool.tag({
      defaultValue: defaults.formShow,
      group: "TABLE 6",
      name: "Show search box?",
      description: "If checked, display search box (default false)."
    }),
    // table6FormMethod: Not implementing. hard-coded to "GET" until "POST" is needed
    // table6FormAction
    table6FormAction: PropTypes.string.tag({
      defaultValue: defaults.formAction,
      group: "TABLE 6",
      name: "Url",
      description:
        "Enter the search url, e.g. 'https://www.washingtonpost.com/recipes/'"
    }),
    // table6FormType: Not implementing. hard-coded to "search" until other type is needed
    // table6FormParam
    table6FormParam: PropTypes.string.tag({
      defaultValue: defaults.formParam,
      group: "TABLE 6",
      name: "Url param",
      description: "Enter the search param, e.g. 'q'"
    }),
    // table6FormPlaceholder
    table6FormPlaceholder: PropTypes.string.tag({
      defaultValue: defaults.formPlaceholder,
      group: "TABLE 6",
      name: "Placeholder text",
      description: "Enter the placeholder text (e.g. 'Search for a recipe')."
    }),
    // table6CtaLabelShow
    table6CtaLabelShow: PropTypes.bool.tag({
      defaultValue: defaults.ctaLabelShow,
      group: "TABLE 6",
      name: "Show CTA?",
      description: "If checked, display CTA (default false)."
    }),
    // table6CtaLabel
    table6CtaLabel: PropTypes.string.tag({
      defaultValue: defaults.ctaLabel,
      group: "TABLE 6",
      name: "CTA text",
      description: "The CTA text. Optional."
    }),
    // table6CtaLabelUrl
    table6CtaLabelUrl: PropTypes.string.tag({
      defaultValue: defaults.ctaLabelUrl,
      group: "TABLE 6",
      name: "CTA link",
      description:
        "The CTA link. Required but falls back to label link if label is due to show."
    }),
    // table6CtaLabelType
    table6CtaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      defaultValue: defaults.ctaLabelType,
      group: "TABLE 6",
      name: "CTA Style",
      description: "Select the look of the the CTA.",
      hidden: false
    }),
    // table6LinkGroup
    table6LinkGroup: PropTypes.string.tag({
      defaultValue: defaults.table6LinkGroup,
      group: "TABLE 6",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking"
    }),
    // AD TABLE 1
    // tableAd1HorizontalDividerWeight
    tableAd1HorizontalDividerWeight: PropTypes.oneOf(
      Object.keys(dividerWeights)
    ).tag({
      labels: dividerWeights,
      defaultValue: defaults.tableAd1HorizontalDividerWeight,
      group: "AD TABLE 1",
      name: "Horizontal divider",
      description:
        "Horizontal dividers appear below the table only when there is another table positioned directly below it. Additionally, if this option and the vertical divider have the same value (Light, Dark, None), then, this divider will be applied to all features within the table."
    }),
    // tableAd1VerticalDividerWeight
    tableAd1VerticalDividerWeight: PropTypes.oneOf(
      Object.keys(verticalDividerWeights)
    ).tag({
      labels: verticalDividerWeights,
      defaultValue: defaults.tableAd1VerticalDividerWeight,
      group: "AD TABLE 1",
      name: "Vertical divider",
      description:
        "Vertical dividers only appear to the left of features within the table. This option is only active if it has the same value (Light, Dark, None) as the horizontal divider."
    }),
    // tableAd1LabelShow
    tableAd1LabelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "AD TABLE 1",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'",
      hidden: true
    }),
    // tableAd1LabelType
    tableAd1LabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "AD TABLE 1",
      name: "Type",
      description: "Select the look of the the label.",
      hidden: true
    }),
    // tableAd1Label
    tableAd1Label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "AD TABLE 1",
      name: "Label text",
      description: "The label text. Optional.",
      hidden: true
    }),
    // tableAd1LabelUrl
    tableAd1LabelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "AD TABLE 1",
      name: "Label link",
      description: "The label link. Optional. No default value.",
      hidden: true
    }),
    // tableAd1LabelSecondaryShow
    tableAd1LabelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "AD TABLE 1",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'",
      hidden: true
    }),
    // tableAd1LabelSecondary
    tableAd1LabelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "AD TABLE 1",
      name: "Secondary label",
      description: "The secondary label text. Optional.",
      hidden: true
    }),
    // tableAd1LabelAlignment
    tableAd1LabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "AD TABLE 1",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'.",
      hidden: true
    }),
    // tableAd1LabelIcon
    tableAd1LabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "AD TABLE 1",
      name: "Select icon to prepend",
      description:
        "The label icon to prepend to the label. Defaults to 'None'.",
      hidden: true
    }),
    // tableAd1LinkGroup
    tableAd1LinkGroup: PropTypes.string.tag({
      defaultValue: defaults.tableAd1LinkGroup,
      group: "AD TABLE 1",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking",
      hidden: true
    }),
    // TABLE 9 (aka FAR-RIGHT TABLE)
    // table9HorizontalDividerWeight
    table9HorizontalDividerWeight: PropTypes.oneOf(
      Object.keys(dividerWeights)
    ).tag({
      labels: dividerWeights,
      defaultValue: defaults.table9HorizontalDividerWeight,
      group: "FAR-RIGHT TABLE",
      name: "Horizontal divider",
      description:
        "Horizontal dividers appear below the table only when there is another table positioned directly below it. Additionally, if this option and the vertical divider have the same value (Light, Dark, None), then, this divider will be applied to all features within the table."
    }),
    // table9VerticalDividerWeight
    table9VerticalDividerWeight: PropTypes.oneOf(
      Object.keys(verticalDividerWeights)
    ).tag({
      labels: verticalDividerWeights,
      defaultValue: defaults.table9VerticalDividerWeight,
      group: "FAR-RIGHT TABLE",
      name: "Vertical divider",
      description:
        "Vertical dividers only appear to the left of features within the table. This option is only active if it has the same value (Light, Dark, None) as the horizontal divider."
    }),
    // table9LabelShow
    table9LabelShow: PropTypes.bool.tag({
      defaultValue: defaults.labelShow,
      group: "FAR-RIGHT TABLE",
      name: "Show label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table9LabelType
    table9LabelType: PropTypes.oneOf(Object.keys(labelTypes)).tag({
      labels: labelTypes,
      defaultValue: defaults.labelType,
      group: "FAR-RIGHT TABLE",
      name: "Type",
      description: "Select the look of the the label."
    }),
    // table9Label
    table9Label: PropTypes.string.tag({
      defaultValue: defaults.label,
      group: "FAR-RIGHT TABLE",
      name: "Label text",
      description: "The label text. Optional."
    }),
    // table9LabelUrl
    table9LabelUrl: PropTypes.string.tag({
      defaultValue: defaults.labelUrl,
      group: "FAR-RIGHT TABLE",
      name: "Label link",
      description: "The label link. Optional. No default value."
    }),
    // table9LabelSecondaryShow
    table9LabelSecondaryShow: PropTypes.bool.tag({
      defaultValue: defaults.labelSecondaryShow,
      group: "FAR-RIGHT TABLE",
      name: "Show secondary label?",
      description:
        "If checked, display label (default false). Note: This configuration option may not work for all Views, such as 'Art Only.'"
    }),
    // table9LabelSecondary
    table9LabelSecondary: PropTypes.string.tag({
      defaultValue: defaults.labelSecondary,
      group: "FAR-RIGHT TABLE",
      name: "Secondary label",
      description: "The secondary label text. Optional."
    }),
    // table9LabelAlignment
    table9LabelAlignment: PropTypes.oneOf(Object.keys(labelAlignments)).tag({
      labels: labelAlignments,
      defaultValue: defaults.labelAlignment,
      group: "FAR-RIGHT TABLE",
      name: "Label alignment",
      description: "The label alignment. Defaults to 'inherit'."
    }),
    // table9LabelIcon
    table9LabelIcon: PropTypes.oneOf(Object.keys(labelIcons)).tag({
      labels: labelIcons,
      defaultValue: defaults.labelIcon,
      group: "FAR-RIGHT TABLE",
      name: "Select icon to prepend",
      description: "The label icon to prepend to the label. Defaults to 'None'."
    }),
    // table9FormShow
    table9FormShow: PropTypes.bool.tag({
      defaultValue: defaults.formShow,
      group: "FAR-RIGHT TABLE",
      name: "Show search box?",
      description: "If checked, display search box (default false)."
    }),
    // table9FormMethod: Not implementing. hard-coded to "GET" until "POST" is needed
    // table9FormAction
    table9FormAction: PropTypes.string.tag({
      defaultValue: defaults.formAction,
      group: "FAR-RIGHT TABLE",
      name: "Url",
      description:
        "Enter the search url, e.g. 'https://www.washingtonpost.com/recipes/'"
    }),
    // table9FormType: Not implementing. hard-coded to "search" until other type is needed
    // table9FormParam
    table9FormParam: PropTypes.string.tag({
      defaultValue: defaults.formParam,
      group: "FAR-RIGHT TABLE",
      name: "Url param",
      description: "Enter the search param, e.g. 'q'"
    }),
    // table9FormPlaceholder
    table9FormPlaceholder: PropTypes.string.tag({
      defaultValue: defaults.formPlaceholder,
      group: "FAR-RIGHT TABLE",
      name: "Placeholder text",
      description: "Enter the placeholder text (e.g. 'Search for a recipe')."
    }),
    // table9CtaLabelShow
    table9CtaLabelShow: PropTypes.bool.tag({
      defaultValue: defaults.ctaLabelShow,
      group: "FAR-RIGHT TABLE",
      name: "Show CTA?",
      description: "If checked, display CTA (default false)."
    }),
    // table9CtaLabel
    table9CtaLabel: PropTypes.string.tag({
      defaultValue: defaults.ctaLabel,
      group: "FAR-RIGHT TABLE",
      name: "CTA text",
      description: "The CTA text. Optional."
    }),
    // table9CtaLabelUrl
    table9CtaLabelUrl: PropTypes.string.tag({
      defaultValue: defaults.ctaLabelUrl,
      group: "FAR-RIGHT TABLE",
      name: "CTA link",
      description:
        "The CTA link. Required but falls back to label link if label is due to show."
    }),
    // table9CtaLabelType
    table9CtaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      defaultValue: defaults.ctaLabelType,
      group: "FAR-RIGHT TABLE",
      name: "CTA Style",
      description: "Select the look of the the CTA.",
      hidden: false
    }),
    // table9LinkGroup
    table9LinkGroup: PropTypes.string.tag({
      defaultValue: defaults.table9LinkGroup,
      group: "FAR-RIGHT TABLE",
      name: "Enter the link group (optional)",
      description: "The link group, e.g. 'opinions', to use for itid tracking"
    }),
    // ctaLabelShow
    ctaLabelShow: PropTypes.bool.tag({
      defaultValue: defaults.ctaLabelShow,
      group: "CHAIN CALL TO ACTION",
      name: "Show CTA?",
      description: "If checked, display label (default false)."
    }),
    // ctaLabel
    ctaLabel: PropTypes.string.tag({
      defaultValue: defaults.ctaLabel,
      group: "CHAIN CALL TO ACTION",
      name: "Text",
      description: "The CTA text. Optional."
    }),
    // ctaLabelUrl
    ctaLabelUrl: PropTypes.string.tag({
      defaultValue: defaults.ctaLabelUrl,
      group: "CHAIN CALL TO ACTION",
      name: "Link",
      description:
        "The CTA link. Required but falls back to label link if label is due to show."
    }),
    // ctaLabelType
    ctaLabelType: PropTypes.oneOf(Object.keys(ctaLabelTypes)).tag({
      labels: ctaLabelTypes,
      defaultValue: defaults.ctaLabelType,
      group: "CHAIN CALL TO ACTION",
      name: "Style",
      description: "Select the look of the the CTA.",
      hidden: false
    }),
    table0LeftIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Top Strip feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in the top strip."
    }),
    table1LeftIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 1, Column 1, feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in the left column of the left table."
    }),
    table1MainIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 1, Column 2, feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in the center column of the left table."
    }),
    table1RightIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 1, Column 3, feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in the right column of the left table."
    }),
    table2LeftIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 2, Column 1, feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in left column of the center table."
    }),
    table2MainIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 2, Column 2, feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in center column of the center table."
    }),
    table2RightIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 2, Column 3, feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in right column of the center table."
    }),
    table3LeftIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 3 feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in table 3."
    }),
    table4LeftIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 4 feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in table 4."
    }),
    table5LeftIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 5 feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in table 5."
    }),
    table6LeftIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Table 6 feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in table 6."
    }),
    tableAd1LeftIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Ad Table 1 feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in ad table 1."
    }),
    table9LeftIds: PropTypes.string.tag({
      group: "Feature IDs",
      name: "Far-Right Table feature IDs",
      description:
        "Enter the comma separated list of ids of the features you would like to appear in the far right table."
    })
  })
};
