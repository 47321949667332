import React, { useContext } from "react";
import PropTypes from "prop-types";
import LoadPowaPlayer from "../output-types/shared/powa-scripts/load-powa-script";

const VideoContext = React.createContext();

export const VideoProvider = ({ children }) => {
  return (
    <VideoContext.Provider value={{}}>
      {children}
      <LoadPowaPlayer loadByFeature={true} />
    </VideoContext.Provider>
  );
};

VideoProvider.propTypes = {
  children: PropTypes.node
};

export const usePowa = () => {
  return useContext(VideoContext);
};
