import React, { useState } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import { useMultiView } from "~/shared-components/MultiViewContext";
import { useAssemblerContext } from "~/shared-components/AssemblerContext";
import { useBreakpoints } from "~/shared-components/BreakpointContext";
import {
  useUserTopicToCountMap,
  getReorderedChildren
} from "./Section.helpers";
import { useFeatureFlag } from "~/components/utilities/use-feature-flag";

const INITIAL_LOAD_LENGTH = 10;

/**
 * Establish an initial number of items to load.
 *
 * 1. Find the main top table chain (assumed to be "hp-top-table-main").
 * 2. If the top table is inside the default load length, use the default.
 * 3. Otherwise, use the index of the top table.
 *
 * This approach is intentionally:
 * - Simple, such that we have easily known edge cases and debugging is straightforward.
 * - Designed to fail towards CLS, instead of towards no lazy loading at all,
 *   because CLS is easier to notice quickly.
 */
const initialLoadedItems = ({ renderChildren, parsedChildren }) => {
  const renderedChildren = renderChildren(parsedChildren);
  const topTableIndex = renderedChildren.findIndex((renderedChild) => {
    return (
      renderedChild.props?.props?.customFields?.displayName ===
      "hp-top-table-main"
    );
  });

  if (topTableIndex < INITIAL_LOAD_LENGTH || topTableIndex === -1) {
    return INITIAL_LOAD_LENGTH;
  }

  return topTableIndex + 1;
};

/**
 * A Section that infinitely scrolls.
 * This reduces page weight, especially on mobile.
 *
 * Uses https://www.npmjs.com/package/react-infinite-scroller
 */
const InfiniteScrollSection = ({ renderChildren, parsedChildren }) => {
  const [loadedItems, setLoadedItems] = useState(() =>
    initialLoadedItems({ renderChildren, parsedChildren })
  );

  const loadFunc = (loadedLength) => {
    setLoadedItems((length) => length + loadedLength);
  };
  const currentRenderedChildren = parsedChildren.slice(0, loadedItems);
  const hasMore = parsedChildren.length > currentRenderedChildren.length;
  // Infinite scroll means the back and refresh buttons need a place to scroll
  // to while the page loads. Buffer the section by some large amount so there's
  // room to scroll, then take that away when all the page is loaded.
  const bufferStyle =
    typeof window !== "undefined" && hasMore
      ? {
          height: `${parsedChildren.length * 500}px`,
          overflow: "auto"
        }
      : {};

  return (
    <React.Fragment>
      <div style={{ ...bufferStyle }}>
        <InfiniteScroll loadMore={loadFunc} hasMore={hasMore}>
          {renderChildren(currentRenderedChildren)}
        </InfiniteScroll>
      </div>
      <noscript>
        {renderChildren(
          parsedChildren.slice(loadedItems, parsedChildren.length)
        )}
      </noscript>
    </React.Fragment>
  );
};

InfiniteScrollSection.propTypes = {
  renderChildren: PropTypes.func,
  parsedChildren: PropTypes.array
};

/**
 * A renderable Section of a page.
 */
export const Section = ({ id, renderChildren, originalChildren }) => {
  const isZoneReorderable = /^renderable-sections-2$/.test(id);
  const { tree } = useAssemblerContext();
  const { bp = "mx" } = useBreakpoints();
  const [children, setChildren] = React.useState(
    JSON.stringify(originalChildren)
  );
  const userTopicToCountMap = useUserTopicToCountMap();

  React.useEffect(() => {
    if (isZoneReorderable) {
      const reorderedChildren = getReorderedChildren(
        originalChildren,
        tree,
        userTopicToCountMap,
        bp
      );
      if (reorderedChildren) {
        setChildren(JSON.stringify(reorderedChildren));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTopicToCountMap, bp]);

  const { isMultiView } = useMultiView();
  const showInfiniteScrollSections = useFeatureFlag(
    "showInfiniteScrollSections"
  );
  if (isMultiView && isZoneReorderable && showInfiniteScrollSections) {
    return (
      <InfiniteScrollSection
        renderChildren={renderChildren}
        parsedChildren={JSON.parse(children)}
      />
    );
  }
  return (
    <React.Fragment>{renderChildren(JSON.parse(children))}</React.Fragment>
  );
};

Section.propTypes = {
  id: PropTypes.string,
  renderChildren: PropTypes.func,
  originalChildren: PropTypes.array
};
