import get from "lodash.get";
import PropTypes from "prop-types";

import { getClasses } from "@washingtonpost/front-end-utils";

import clonedeep from "lodash.clonedeep";
import kebabcase from "lodash.kebabcase";
import snakecase from "lodash.snakecase";

import { fetchProps } from "./data";

export const getAspectRatio = ({ artSlot, overrides }) => {
  let width = get(artSlot, "raw.coverArtWidth");
  let height = get(artSlot, "raw.coverArtHeight");
  // NOTE: Likely means that the image is coming from the
  // backing content, not alternateArt
  const { anglerfishId } = artSlot;
  const { alternateArt } = overrides;
  const isAltArtImgUrl = alternateArt && !artSlot.isHtml;
  if (
    anglerfishId ||
    isAltArtImgUrl ||
    (alternateArt && !artSlot.raw.coverArtUrl) ||
    !width
  ) {
    return artSlot.aspectRatio;
  }
  // make sure these are numbers base 10
  width = Number.parseFloat(width, 10);
  height = Number.parseFloat(height, 10);
  // calculate the aspect ratio
  return Number.parseFloat((width / height).toFixed(2));
};

export const getAspectRatioClasses = (aspectRatio = 1.5) => {
  return getClasses("", {
    // "is-horizontal-art": aspectRatio > 1,
    "is-square-art": aspectRatio === 1,
    "is-vertical-art": aspectRatio < 1
  });
};

const getType = ({ content = {}, type = "web" }) => {
  // Return a link.type based on a tag in the article.
  // Current supported types include:
  // -- force-webview: web

  const tags = get(content, "taxonomy.tags", []);
  const tagSlug = tags.map(({ slug }) => slug);

  if (tagSlug.includes("force-webview")) return "web";

  // If link.type does not depend on a specific tag,
  // this is the default behavior.
  if (type === "story") return "article";
  return type;
};

/**
 * Returns whether the artSlot object and promoImage object represent the same image
 * @param {obj} artSlot - an artSlot object
 * @param {obj} promoImage - a promoImage object
 * @returns {boolean}
 */
export function isSameImage(artSlot, promoImage) {
  if (artSlot?.url && artSlot.url === promoImage?.url) return true;
  if (artSlot?.anglerfishId && artSlot.anglerfishId === promoImage?._id)
    return true;
  return false;
}

export const getLink = ({ content = {}, overrides = {} }) => {
  const keys = {
    url: ["fusion_additions.links_to_use.basic", "blurbUrl"],
    offline: ["fusion_additions.links_to_use.offline", ""],
    canonical: [
      "fusion_additions.links_to_use.dangerous.canonical",
      "blurbUrl"
    ],
    type: ["type", "linkType"],
    subtype: ["subtype", "subtype"],
    lastModified: ["publish_date", "lastModified"],
    displayDate: ["display_date", "displayDate"]
  };

  const fetchedProps = fetchProps({
    data: content,
    keys,
    overrides
  });

  const { canonical, offline, lastModified, subtype, displayDate } =
    fetchedProps;
  let { url, type = "web" } = fetchedProps;

  if (url) {
    url = url.startsWith("/") ? `https://www.washingtonpost.com${url}` : url;
  }

  type = getType({ content, type });

  // NOTE: Non-canonical links are suspect so make them of type 'web'. Revisit as necessary.
  const reMapSubtypeToType = /^(audio|graphic)$/;
  let linkType;
  if (url !== canonical) linkType = "web";
  else if (reMapSubtypeToType.test(subtype)) linkType = subtype;
  else if (/^(btw-|interactive|recipe)/.test(subtype)) linkType = "web";
  else linkType = type;

  let linkSubtype;
  if (!/default/.test(subtype)) linkSubtype = subtype;

  const offlineLink = !offline
    ? undefined
    : {
        url: offline,
        type,
        last_modified: lastModified,
        display_date: displayDate
      };
  const link = !url
    ? undefined
    : {
        url,
        type: linkType,
        subtype: linkSubtype,
        last_modified: lastModified,
        display_date: displayDate
      };
  return { link, offlineLink };
};

getLink.propTypes = {
  content: PropTypes.object,
  overrides: PropTypes.object
};

/**
 * Takes the show/hide fields and translates them to the
 * correct keys for StoryCards
 *
 * @param {object} obj - the customFields object from PB
 */
export const addComponentFlags = (obj) => {
  return {
    ...obj,
    Image: obj.artHide,
    Label: obj.labelShow,
    Headline: obj.headlineHide,
    Blurb: obj.blurbHide,
    Sigline: obj.siglineHide,
    Audio: obj.audioHide,
    LiveTicker: obj.liveTickerHide
  };
};

/**
 * Take in the initial storycard layout. Mutate it based on the changes in "changes" object.
 * The changes object should be formatted like `{  itemToMove:  before : "AnchorItem"  }`
 * example: `transformLayout(layout, { Label: { before: "Headline" } });`
 *
 * @param {object} layout - the layout object as defined in StoryCards presets.js
 * @param {*} changes - an object describing location changes of items in the layout
 */
export const transformLayout = (layout, changes) => {
  const newLayout = clonedeep(layout);
  Object.keys(changes).forEach((change) => {
    const sections = Object.keys(newLayout);

    // Look for the previous location of the change item
    // and remove it from the layout if it's a legitimate move
    const remove = () => {
      sections.forEach((section) => {
        if (newLayout[section].items.indexOf(change) !== -1) {
          newLayout[section].items.splice(
            newLayout[section].items.indexOf(change),
            1
          );
        }
      });
    };

    // NOTE: Look for the location the new item is supposed to go.
    // prepend or append it to a section
    const prependToItem = changes[change].prependTo;
    const appendToItem = changes[change].appendTo;
    // ... or place it before or after.
    const beforeItem = changes[change].before;
    const afterItem = changes[change].after;

    sections.some((section) => {
      if (section === prependToItem) {
        remove();
        newLayout[section].items.unshift(change);
        return true;
      }
      if (section === appendToItem) {
        remove();
        newLayout[section].items.push(change);
        return true;
      }
      if (newLayout[section].items.indexOf(beforeItem) !== -1) {
        remove();
        newLayout[section].items.splice(
          newLayout[section].items.indexOf(beforeItem),
          0,
          change
        );
        return true;
      }
      if (newLayout[section].items.indexOf(afterItem) !== -1) {
        remove();
        newLayout[section].items.splice(
          newLayout[section].items.indexOf(afterItem) + 1,
          0,
          change
        );
        return true;
      }
      return false;
    });
  });

  return newLayout;
};

export const labelLocationTransform = (layout, labelPosition) => {
  if (labelPosition === "Above Headline") {
    return transformLayout(layout, { Label: { before: "Headline" } });
  }
  return layout;
};

export const relatedLinksLocationTransformHiPri = (layout, position) => {
  if (position === "Bottom") {
    return transformLayout(layout, { RelatedLinks: { prependTo: "bottom" } });
  }
  return layout;
};

export const audioLocationTransform = (layout, position) => {
  if (position === "Below Related Links") {
    return transformLayout(layout, { Audio: { after: "RelatedLinks" } });
  }
  if (position === "Below Art") {
    return transformLayout(layout, { Audio: { after: "Art" } });
  }
  if (position === "Above Art") {
    return transformLayout(layout, { Audio: { before: "Art" } });
  }
  return layout;
};

export const simplifyLayout = (layout, overrides) => {
  const newLayout = Object.keys(layout).reduce((acc, section) => {
    if (acc[section].items && acc[section].items.length) {
      // Remove Art if it's hidden
      // TODO: Consider removing each hidden component. Doing Art cuz it
      //   determines so much about the layout
      const indexOfArt = acc[section].items.indexOf("Art");
      if (!!overrides.artHide && indexOfArt !== -1) {
        acc[section].items.splice(indexOfArt, 1);
      }
    }

    // Remove section if no items
    if (!acc[section].items || !acc[section].items.length) delete acc[section];

    return acc;
  }, clonedeep(layout));

  let sections = Object.keys(newLayout);

  // If empty left AND not empty right (or vice versa), left/right should go into top
  if (newLayout.left && !newLayout.right) {
    if (!newLayout.top) newLayout.top = { items: [] };
    newLayout.left.items = [...newLayout.top.items, ...newLayout.left.items];
    // TODO: Revisit the wisdom of this delete
    delete newLayout.left.isArtSlot;
    Object.assign(newLayout.top, newLayout.left);
    delete newLayout.left;
  }
  if (newLayout.right && !newLayout.left) {
    if (!newLayout.top) newLayout.top = { items: [] };
    newLayout.right.items = [...newLayout.top.items, ...newLayout.right.items];
    // TODO: Revisit the wisdom of this delete
    delete newLayout.right.isArtSlot;
    Object.assign(newLayout.top, newLayout.right);
    delete newLayout.right;
  }
  // If not empty bottom AND empty left AND empty right, put everything in top
  if (newLayout.bottom && !newLayout.right && !newLayout.left) {
    if (!newLayout.top) newLayout.top = { items: [] };
    newLayout.bottom.items = [
      ...newLayout.top.items,
      ...newLayout.bottom.items
    ];
    // TODO: Revisit the wisdom of this delete
    delete newLayout.bottom.isArtSlot;
    Object.assign(newLayout.top, newLayout.bottom);
    delete newLayout.bottom;
  }

  sections = Object.keys(newLayout);
  if (sections.length === 1 && !newLayout.top) {
    const section = sections[0];
    // TODO: Revisit the wisdom of this delete
    delete newLayout[section].isArtSlot;
    newLayout.top = newLayout[section];
    delete newLayout[section];
  }

  return newLayout;
};

export const getSubComponentArrangements = ({
  layout,
  overrides,
  json,
  outputType = "default"
}) => {
  const keysInJson = Object.keys(json);
  // NOTE: for jsonapp keys that don't match web component names
  const webToJsonappComponentMap =
    outputType === "jsonapp"
      ? {
          Art: "Media",
          ...(keysInJson.includes(snakecase("AudioArticle"))
            ? { Audio: "AudioArticle" }
            : {}),
          Blurb: "Blurbs",
          CallToAction: "CTA",
          Recipe: "RecipeInfo",
          Sigline: "Signature"
        }
      : {};
  let newLayout = Object.keys(clonedeep(layout)).reduce((sections, section) => {
    sections[section].items = sections[section].items.reduce((acc, name) => {
      if (/^(left|right)$/.test(section)) {
        // NOTE: slot width
        if (sections[section].isArtSlot) {
          sections[section].width = kebabcase(overrides.artSize);
        } else {
          const valign = overrides.textVerticalAlignment;
          if (valign && !/^top$/.test(valign)) {
            sections[section].valign = valign;
          }
        }
      }
      name = webToJsonappComponentMap[name] || name;
      name = outputType === "jsonapp" ? snakecase(name) : name;
      if (keysInJson.indexOf(name) !== -1 && json[name] !== undefined) {
        acc.push(name);
      }
      return acc;
    }, []);

    return sections;
  }, clonedeep(layout));

  // NOTE: simplify layout: Remove empty sections, etc.
  newLayout = simplifyLayout(newLayout, overrides);

  // NOTE: Remove un-needed keys
  Object.keys(newLayout).forEach((s) => {
    Object.keys(newLayout[s]).forEach((key) => {
      if (/^(left|right)$/.test(s)) {
        if (!key.match(/^(items|valign|width)/)) delete newLayout[s][key];
      } else {
        // eslint-disable-next-line no-lonely-if
        if (!key.match(/^(items)/)) delete newLayout[s][key];
      }
    });
  });

  return {
    default: newLayout
  };
};

getSubComponentArrangements.propTypes = {
  layout: PropTypes.object,
  overrides: PropTypes.object,
  json: PropTypes.object,
  outputType: PropTypes.string
};

export const allTransformsOrder = ({ layout, overrides }) => {
  // TODO: implement a clear configuration of the order layout transofrms are applied

  let newLayout = clonedeep(layout);

  // START: Components moved to different layout zone
  newLayout = relatedLinksLocationTransformHiPri(
    newLayout,
    overrides.relatedLinksPosition
  );
  // END: Components moved to different layout zone

  // START: Components relative to other components
  newLayout = labelLocationTransform(newLayout, overrides.labelPosition);

  newLayout = audioLocationTransform(newLayout, overrides.audioPosition);
  // END: Components relative to other components

  newLayout = simplifyLayout(newLayout, overrides);

  return newLayout;
};

export const defaultMenuItemProps = {
  setEntity: () => null,
  value: ""
};

export const isFeatureHiddenOnMobile = (overrides = {}) => {
  // TODO: Ideally, limitMobileOnly would be limit instead. However, not all methods
  // calling this first run overrides throught applyMobilePresets
  const {
    hideOnDesktop = false,
    limitMobileOnly = "",
    mobilePreset = ""
  } = overrides;
  return (
    /HIDE ENTIRE FEATURE/.test(mobilePreset) ||
    (/^As is$/.test(mobilePreset) && hideOnDesktop) ||
    /^0$/.test(limitMobileOnly)
  );
};
