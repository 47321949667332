import React from "react";
import PropTypes from "@arc-fusion/prop-types";
import get from "lodash.get";
import { useMediaFeatures } from "~/shared-components/video/hooks";

/**
 * Conditionally load powa player script if a video exists on the page
 *
 * @returns {node} The powa script tag
 *
 * @TODO extend as new video instances are added (i.e. related content, lead art, etc...)
 * */

const LoadPowaPlayerComponent = (props) => {
  const renderOrca = useMediaFeatures("useOrcaAssembler");
  if (renderOrca) return null;

  const { loadByFeature, isHomepage, isFront } = props;

  const videoEnvs = {};

  if (!loadByFeature) return null;

  const ptvEnv = get(videoEnvs, "PTVENV", "prod");
  const vaEnv = get(videoEnvs, "VAENV", "prod");
  const playerRoot = "https://www.washingtonpost.com/player";
  const loadPoWaDrive =
    videoEnvs && typeof videoEnvs.powaDrive === "boolean"
      ? videoEnvs.powaDrive
      : true;

  const POWA_BOOT = `${playerRoot}/${ptvEnv}/powaBoot.js`;
  const POWA_DRIVE = `${playerRoot}/${ptvEnv}/powaDrive.js?org=wapo`;
  const POWA_ORG = `${playerRoot}/${ptvEnv}/org/wapo.js?org=wapo`;
  const VA = `https://www.washingtonpost.com/videoscripts/VideoAnalytics/${vaEnv}/VideoAnalytics.js`;
  const VID_EXCLUSIONS =
    "https://www.washingtonpost.com/wp-stat/advertising/pseudo-static/vidExclusions/vid_exclusions.min.js";

  return (
    <React.Fragment>
      {isHomepage || isFront ? (
        <script
          src={POWA_BOOT}
          async
          data-powa-script
          data-loaded-via="powa-manifest"
        />
      ) : null}
      <link rel="preload" as="script" href={POWA_BOOT} />
      <link rel="preload" as="script" href={POWA_ORG} />
      {loadPoWaDrive && <link rel="preload" as="script" href={POWA_DRIVE} />}
      <link rel="preload" as="script" href={VID_EXCLUSIONS} />
      {/* See ../../../output-types/default */}
      <link rel="preload" as="script" href={VA} />
      <link
        rel="preload"
        as="image"
        href="https://www.washingtonpost.com/player/prod/img/wp_grey.jpg"
      />
    </React.Fragment>
  );
};
LoadPowaPlayerComponent.propTypes = {
  loadByFeature: PropTypes.bool,
  isHomepage: PropTypes.bool,
  isFront: PropTypes.bool
};
export default LoadPowaPlayerComponent;

export const loadPowaPlayer = ({ loadByFeature, isHomepage, isFront }) => {
  const videoEnvs = {};

  if (!loadByFeature) return null;

  const ptvEnv = get(videoEnvs, "PTVENV", "prod");
  const vaEnv = get(videoEnvs, "VAENV", "prod");
  const playerRoot = "https://www.washingtonpost.com/player";
  const loadPoWaDrive =
    videoEnvs && typeof videoEnvs.powaDrive === "boolean"
      ? videoEnvs.powaDrive
      : true;

  const POWA_BOOT = `${playerRoot}/${ptvEnv}/powaBoot.js`;
  const POWA_DRIVE = `${playerRoot}/${ptvEnv}/powaDrive.js?org=wapo`;
  const POWA_ORG = `${playerRoot}/${ptvEnv}/org/wapo.js?org=wapo`;
  const VA = `https://www.washingtonpost.com/videoscripts/VideoAnalytics/${vaEnv}/VideoAnalytics.js`;
  const VID_EXCLUSIONS =
    "https://www.washingtonpost.com/wp-stat/advertising/pseudo-static/vidExclusions/vid_exclusions.min.js";

  return [
    isHomepage || isFront ? (
      <script
        src={POWA_BOOT}
        async
        data-powa-script
        data-loaded-via="powa-manifest"
      />
    ) : null,
    <link rel="preload" as="script" href={POWA_BOOT} key="preload-boot" />,
    <link rel="preload" as="script" href={POWA_ORG} key="preload-org" />,
    loadPoWaDrive && (
      <link rel="preload" as="script" href={POWA_DRIVE} key="preload-drive" />
    ),
    <link
      rel="preload"
      as="script"
      href={VID_EXCLUSIONS}
      key="preload-exclusions"
    />,
    // See ../../../output-types/default
    <link rel="preload" as="script" href={VA} key="preload-analytics" />,
    <link
      rel="preload"
      as="image"
      href="https://www.washingtonpost.com/player/prod/img/wp_grey.jpg"
      key="preload-image"
    />
  ];
};
