/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from "react";
import { Orca } from "@washingtonpost/media-components/core/index";
import { useMediaFeatures } from "./hooks";
import { isProd } from "~/components/utilities/env";

const VideoElement = ({
  ads,
  aspectRatio,
  autoinit,
  autoplay,
  blurb,
  customBtnText,
  durationOrLive,
  unresizedAlternateArt,
  alternateArt,
  isLive,
  isLooping,
  muted,
  playthrough,
  showPreview,
  showPreview2,
  showTitle,
  uuid,
  scriptUrl,
  featureName,
  index,
  loadByFeature,
  videoData,
  isSubscriber,
  forcePlay,
  playerType
}) => {
  const { isEnabled: renderOrca, isLoading } =
    useMediaFeatures("useOrcaAssembler");

  const playerRef = useRef();
  const [powa, setPowa] = useState("");
  const [powaReady, setPowaListeners] = useState(false);
  const handleVideoOnLoad = () => {
    if (typeof window === "undefined") {
      return null;
    }
    const errorListener = (event) => {
      window.console.log("powa error", event);
      window.removeEventListener("powaError", errorListener);
    };
    window.addEventListener("powaError", errorListener);
    const renderListener = (event) => {
      if (
        playerRef.current &&
        event.detail.powa.getID() === playerRef.current.id
      )
        setPowa(event.detail.powa);
      setPowaListeners(true);
      window.removeEventListener("powaRenderer", renderListener);
    };
    window.addEventListener("powaRender", renderListener);
    return true;
  };

  const powaBootEffect = () => {
    if (!window.powaBootScript && typeof window !== "undefined") {
      const tag = document.createElement("script");
      tag.id = "powaBootScript";
      tag.src = scriptUrl;
      tag.async = true;
      tag["data-powa-script"] = true;
      tag["data-loaded-via"] = "powa-manifest";
      tag.onload = handleVideoOnLoad;
      document.body.appendChild(tag);
    } else {
      handleVideoOnLoad();
    }
  };

  useEffect(() => {
    if (renderOrca || isLoading) return;

    powaBootEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (renderOrca || isLoading) return;

    if (playerRef && playerRef.current) {
      if (loadByFeature && videoData) {
        window.powaData = window.powaData || {};
        if (videoData._id) window.powaData[videoData._id] = videoData;
      }
      if (isSubscriber()) {
        playerRef.current.setAttribute("data-viewport-autoplay", false);
      }
      playerRef.current.classList.remove("powa-skip");
      playerRef.current.classList.add("powa");
      if (window.powaBoot) {
        window.powaBoot();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubscriber, loadByFeature, playerRef, videoData, isLoading]);

  useEffect(() => {
    if (powaReady) {
      // istanbul ignore next
      if (powa && forcePlay) {
        powa.play();
      }
    }
    return () => {};
  }, [featureName, forcePlay, index, powa, powaReady]);

  const dataAttrs = {
    "data-org": "wapo",
    "data-ads": ads,
    "data-ad-bar": "1",
    "data-allow-share": true,
    "data-aspect-ratio": aspectRatio,
    "data-autoinit": autoinit,
    "data-autoplay-on-desktop": autoplay,
    "data-blurb": blurb,
    "data-custom-btn-text": customBtnText,
    "data-duration-or-live": durationOrLive,
    "data-event400": showPreview || showPreview2,
    "data-alternate-art": alternateArt,
    "data-live": isLive,
    "data-loop": isLooping,
    "data-muted": muted,
    "data-playthrough": playthrough,
    "data-promo-video": true,
    "data-preview": showPreview,
    "data-preview2": showPreview2,
    "data-show-title": showTitle,
    "data-subtitles": false,
    "data-uuid": uuid,
    "data-player-type": playerType
  };

  const orcaProps = {
    uuid,
    video: videoData,
    config: {
      hasAds: ads,
      aspectRatio,
      alternateArt: unresizedAlternateArt,
      allowPlaythrough: playthrough,
      showDurationOrLive: durationOrLive,
      isLivePreviewEnabled: showPreview2,
      isSticky: false,
      allowShare: true,
      showBtnText: !!customBtnText,
      customBtnText,
      isAutoplayOverride: autoplay,
      isMutedOverride: muted,
      playerTypeOverride: playerType,
      ...(!isProd() && {
        adOverrides: { adZoneOverride: "wpni.video.test/appsalwayson" }
      })
    }
  };

  if (isLoading) return <React.Fragment></React.Fragment>;

  return (
    <React.Fragment>
      <div>{renderOrca && <Orca {...orcaProps} />}</div>
      {!renderOrca && (
        <div
          ref={playerRef}
          data-testid="video-js"
          className="powa-skip"
          {...dataAttrs}
        />
      )}
    </React.Fragment>
  );
};

export default VideoElement;
