import React from "react";
import Lazy from "lazy-child";
import PropTypes from "prop-types";

const WapoLazyChild = ({ eager, children, ...rest }) => {
  return eager ? children : <Lazy {...rest}>{children}</Lazy>;
};

export default WapoLazyChild;

WapoLazyChild.propTypes = {
  renderPlaceholder: PropTypes.func,
  eager: PropTypes.bool,
  offsetTop: PropTypes.number,
  offsetBottom: PropTypes.number,
  throttle: PropTypes.number,
  children: PropTypes.node
};

WapoLazyChild.defaultProps = {
  // eslint-disable-next-line react/display-name
  renderPlaceholder: (ref) => <div ref={ref} />,
  eager: false,
  offsetTop: 300,
  offsetBottom: 300
};
