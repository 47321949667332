import { useFeatureFlagLoading } from "~/components/utilities/use-feature-flag";
import { getQueryParams } from "~/components/utilities/history";

/**
 * Hook to determine if new Media features should be used or not
 *
 * @returns {boolean}
 */
export const useMediaFeatures = (flag = "useMediaFeatures") => {
  const hasS3Flag = useFeatureFlagLoading(flag);

  // If query param flag exists, prioritize its value to enable/disable new features
  if (typeof window !== "undefined") {
    const queryParams = getQueryParams(window.location.href);
    if (queryParams?.[flag] === "true") return { isEnabled: true };
    if (queryParams?.[flag] === "false") return { isEnabled: false };
  }

  // Use new Media features if S3 flag is enabled
  return hasS3Flag;
};
