import { Box, styled } from "@washingtonpost/wpds-ui-kit";
import { bufferBelowLastBarStyle as hotTopicsStyle } from "../hot-topics/default";

export const AlwaysPresentContent = styled(Box, {
  variants: {
    display: {
      true: {
        display: "block"
      },
      false: {
        display: "none"
      }
    }
  },
  ...hotTopicsStyle
});
