import get from "lodash.get";

import {
  IMPLICIT_GRID_INFO,
  createImplicitGridStyles
} from "~/components/layouts/utilities/grid-helpers";

import {
  dividerWeights as commonDividerWeights,
  labelTypes as commonLabelTypes,
  labelIcons as commonLabelIcons,
  ctaLabelTypes as commonCtaLabelTypes
} from "~/components/features/fronts/utilities/common-front-properties";

export const HPLayouts = [
  "Wide left - 4 separate tables",
  "Wide left - 3-col table at left",
  "Wide left - 2-col table at left",
  "Wide left - 2-col table at center",
  "Wide left - dual 2-col tables",
  "Extra-wide left",
  "======",
  "Even split - 3 separate tables",
  "Even split - 2-col table at left",
  "Even split - 2-col table at right",
  "Even split - 3-col table full width",
  "======",
  "Double wide",
  "Double wide with far-right table",
  "======",
  "Wide center - 4 separate tables",
  "Wide center - 3-col table at left",
  "Wide center - 2-col table at left",
  "Wide center - 2-col table at center",
  "Wide center - 3-col table at right",
  "Wide center - dual 2-col tables",
  "======",
  "Extra-wide center - 3 separate tables",
  "Extra-wide center - 2-col table at left",
  "Extra-wide center - 3-col table full width",
  "======",
  "Super-wide left",
  "Super-wide right",
  "======",
  "Stacked strips with far-right table - 2 tables",
  "Stacked strips with far-right table - 4 tables",
  "Stacked strips with far-right table - 6 tables",
  "Stacked strips with 2-col far-right table - 6 tables",
  "======",
  "Stacked strips with split tables",
  "Stacked strips with split tables and 2-col far-right table",
  "======",
  "Full span",
  "Tiling"
];

// NOTE: For backward compatibility
export const HPLayoutSynonyms = {
  // NOTE: Old name on left, current name on right.
  // Even older remappings must be kept current.
  // NOTE: Early, undated remapping
  "Average News Day": "Wide center - 3-col table at left",
  "Full Span": "Full span",
  "Two Even Columns": "Even split - 3 separate tables",
  "Package + Other News": "Extra-wide left",
  "Huge Image Center": "Extra-wide center - 3-col table full width",
  "Huge Visual": "Super-wide right",
  // NOTE: Remapping ~08/25/2020
  "Wide left": "Wide left - 3-col table at left",
  "Wide center": "Wide center - 3-col table at left",
  "Extra-wide center": "Extra-wide center - 3-col table full width",
  // NOTE: Remapping ~09/24/2020
  "Even split": "Even split - 3 separate tables"
};

export const fixLayoutName = (layout = "") =>
  HPLayouts.includes(layout) ? layout : HPLayoutSynonyms[layout];

// NOTE: In the label key/value pairs below, if you want to change
// what the editor sees, change the value and leave the key alone.
export const labelAlignments = {
  left: "left",
  center: "center"
};

export const dividerWeights = commonDividerWeights;
export const verticalDividerWeights = {
  normal: "Light (default)",
  bold: "Dark (must match horizontal divider)",
  none: "None (must match horizontal divider)"
};
export const labelTypes = commonLabelTypes;
export const labelIcons = commonLabelIcons;
export const ctaLabelTypes = commonCtaLabelTypes;

// If you need to change responsive styles for a table change the child
// styles or the colum values (left, main, right) as appropriate
export const layoutStyles = {
  "Wide left - 4 separate tables": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 9,
        "--c-start-md": 9,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table3: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        // md is tiling
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md is tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide left - 4 separate tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 9,
        "--c-start-md": 9,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table3: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        // md is tiling
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md is tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide center - 4 separate tables": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 5,
        "--c-start-lg": 5,
        "--c-start-md": 5,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table3: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        // md is tiling
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md is tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide center - 4 separate tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 5,
        "--c-start-lg": 5,
        "--c-start-md": 5,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table3: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        // md is tiling
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md is tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide center - 3-col table at left": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 5,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10
        },
        right: {
          "--c-start-mx": 13,
          "--c-start-lg": 13,
          "--c-span-mx": 4,
          "--c-span-lg": 4
        } // Only applies to "right". So we want to ensure the other columns overwrite the responsive styles
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide center - 3-col table at left with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 5,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10
        },
        right: {
          "--c-start-mx": 13,
          "--c-start-lg": 13,
          "--c-span-mx": 4,
          "--c-span-lg": 4
        } // Only applies to "right". So we want to ensure the other columns overwrite the responsive styles
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide center - 2-col table at left": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 5,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        // md tiling
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide center - 2-col table at left with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 5,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2,
        "--r-span-lg": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        // md tiling
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide center - 2-col table at center": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        // md tiling
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 5,
        "--c-start-lg": 5,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide center - 2-col table at center with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        // md tiling
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 5,
        "--c-start-lg": 5,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide center - 3-col table at right": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        // tiling lg md
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        // tiling lg md
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 5,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        right: {
          "--c-start-mx": 13,
          "--c-start-lg": 13,
          // tiling md
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          // tiling md
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 16
      }
    }
  },
  "Wide center - 3-col table at right with top strip": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        // tiling lg md
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        // tiling lg md
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table0: {
      styles: {
        "--c-start-mx": 5,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 5,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        right: {
          "--c-start-mx": 13,
          "--c-start-lg": 13,
          // tiling md
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          // tiling md
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 16
      }
    }
  },
  "Wide center - dual 2-col tables": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 6,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 7,
          "--c-start-md": 5,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 10,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 8
      }
    }
  },
  "Wide center - dual 2-col tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 6,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 7,
          "--c-start-md": 5,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 10,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 8
      }
    }
  },
  "Wide left - 3-col table at left": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 9,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4
        },
        right: {
          "--c-start-mx": 13,
          "--c-start-lg": 13,
          "--c-span-mx": 4,
          "--c-span-lg": 4
        } // Only applies to "right". So we want to ensure the other columns overwrite the responsive styles
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide left - 3-col table at left with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 9,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4
        },
        right: {
          "--c-start-mx": 13,
          "--c-start-lg": 13,
          "--c-span-mx": 4,
          "--c-span-lg": 4
        } // Only applies to "right". So we want to ensure the other columns overwrite the responsive styles
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide left - 2-col table at left": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        // md tiling
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide left - 2-col table at left with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 8,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2,
        "--r-span-lg": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        // md tiling
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        // md tiling
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide left - 2-col table at center": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 9,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Wide left - 2-col table at center with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 9,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Even split - 3 separate tables": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 6,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 6,
        "--c-span-sm": 10
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 9,
        "--c-start-md": 7,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 6,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 6,
        "--c-span-sm": 10
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Even split - 3 separate tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 6,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 6,
        "--c-span-sm": 10
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 9,
        "--c-start-md": 7,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 6,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 6,
        "--c-span-sm": 10
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Even split - 2-col table at left": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Even split - 2-col table at left with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Even split - 2-col table at right": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 6,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 6,
        "--c-span-md": 12,
        "--c-span-sm": 10
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 7,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 10,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 6,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 7,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 12,
        "--c-start-lg": 1,
        "--c-span-lg": 10
      }
    }
  },
  "Even split - 2-col table at right with top strip": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 6,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2,
        "--r-span-lg": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 6,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table0: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 7,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 10,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 10,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 7,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 10,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 6,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 7,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 12,
        "--c-start-lg": 1,
        "--c-span-lg": 10
      }
    }
  },
  "Even split - 3-col table full width": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        right: {
          "--c-start-mx": 17,
          // lg, md are tiling
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          // lg, md are tiling
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    }
  },
  "Even split - 3-col table full width with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        right: {
          "--c-start-mx": 17,
          // lg, md are tiling
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          // lg, md are tiling
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    }
  },
  "Wide left - dual 2-col tables": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 10,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 11,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 6,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 8
      }
    }
  },
  "Wide left - dual 2-col tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 10,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 9,
          "--c-start-lg": 11,
          "--c-start-md": 9,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 6,
          "--c-span-md": 4,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 8
      }
    }
  },
  "Extra-wide left": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 12,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Extra-wide left with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 12,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 13,
        "--c-start-lg": 13,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Extra-wide center - 3 separate tables": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        // sm is tiling
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        // sm is tiling
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 5,
        "--c-start-lg": 5,
        "--c-start-md": 5,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 12,
          "--c-span-lg": 12,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 6,
          "--c-span-lg": 6,
          "--c-span-md": 4,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        right: {
          "--c-start-mx": 7,
          "--c-start-lg": 7,
          "--c-start-md": 5,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 6,
          "--c-span-lg": 6,
          "--c-span-md": 4,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 8
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Extra-wide center - 3 separate tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        // sm is tiling
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 4,
        "--c-span-md": 4,
        // sm is tiling
        "--c-span-xs": 1
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 5,
        "--c-start-lg": 5,
        "--c-start-md": 5,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 8,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 12,
          "--c-span-lg": 12,
          "--c-span-md": 8,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 6,
          "--c-span-lg": 6,
          "--c-span-md": 4,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        right: {
          "--c-start-mx": 7,
          "--c-start-lg": 7,
          "--c-start-md": 5,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 6,
          "--c-span-lg": 6,
          "--c-span-md": 4,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-span-mx": 12,
        "--c-span-lg": 12,
        "--c-span-md": 8
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Extra-wide center - 3-col table full width": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        ...createImplicitGridStyles()
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 12,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        right: {
          "--c-start-mx": 17,
          // lg is tiling
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          // lg is tiling
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    }
  },
  "Extra-wide center - 3-col table full width with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        ...createImplicitGridStyles()
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 12,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        right: {
          "--c-start-mx": 17,
          // lg is tiling
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          // lg is tiling
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    }
  },
  "Extra-wide center - 2-col table at left": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 12,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Extra-wide center - 2-col table at left with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 12,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Super-wide left": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        ...createImplicitGridStyles()
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 17,
          "--c-start-lg": 13,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    }
  },

  "Super-wide left with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        ...createImplicitGridStyles()
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 17,
          "--c-start-lg": 13,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    }
  },
  "Super-wide right": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        ...createImplicitGridStyles()
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    }
  },
  "Super-wide right with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        ...createImplicitGridStyles()
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 12,
          "--c-span-md": 12,
          "--c-span-sm": 10
        }
      }
    }
  },
  "Full span": {
    allcontent: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        ...createImplicitGridStyles()
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        ...createImplicitGridStyles()
      }
    }
  },
  Tiling: {
    allcontent: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        ...createImplicitGridStyles()
      },
      childStyles: {}
    }
  },
  "Double wide": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 10,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 10,
          "--c-span-lg": 8,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 5,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        },
        right: {
          "--c-start-mx": 6,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-span-mx": 5,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 11,
        "--c-start-lg": 9,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 10,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 10,
          "--c-span-lg": 8,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 5,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        },
        right: {
          "--c-start-mx": 6,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-span-mx": 5,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-span-mx": 10,
        "--c-span-lg": 8
      }
    }
  },
  "Double wide with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 20,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 10,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 10,
          "--c-span-lg": 8,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 5,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        },
        right: {
          "--c-start-mx": 6,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-span-mx": 5,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 11,
        "--c-start-lg": 9,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 10,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 10,
          "--c-span-lg": 8,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 5,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        },
        right: {
          "--c-start-mx": 6,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-span-mx": 5,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-span-mx": 10,
        "--c-span-lg": 8
      }
    }
  },
  "Double wide with far-right table": {
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        },
        right: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 9,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        },
        right: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Double wide with far-right table with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    table1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        },
        right: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        }
      }
    },
    table2: {
      styles: {
        "--c-start-mx": 9,
        "--c-start-lg": 9,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 8,
          "--c-span-lg": 8,
          "--c-span-md": 12,
          "--c-span-sm": 10
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        },
        right: {
          "--c-start-mx": 5,
          "--c-start-lg": 5,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-span-mx": 4,
          "--c-span-lg": 4,
          "--c-span-md": 6,
          "--c-span-sm": 5
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-span-mx": 8,
        "--c-span-lg": 8
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 2
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with far-right table - 2 tables": {
    // NOTE: These tables are configured exactly the same
    ...["table1", "table2", "tableAd1"].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: /tableAd1/.test(key)
            ? {
                "--c-start-mx": 1,
                "--c-start-lg": 1,
                "--c-start-md": 1,
                "--c-start-sm": 1,
                "--c-start-xs": 1,
                "--c-span-mx": 16,
                "--c-span-lg": 16,
                "--c-span-md": 12,
                "--c-span-sm": 10,
                "--c-span-xs": 1
              } // fullspan
            : {} // tiling
        }
      };
      return acc;
    }, {}),
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 3
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with far-right table - 2 tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    // NOTE: These tables are configured exactly the same
    ...["table1", "table2", "tableAd1"].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: /tableAd1/.test(key)
            ? {
                "--c-start-mx": 1,
                "--c-start-lg": 1,
                "--c-start-md": 1,
                "--c-start-sm": 1,
                "--c-start-xs": 1,
                "--c-span-mx": 16,
                "--c-span-lg": 16,
                "--c-span-md": 12,
                "--c-span-sm": 10,
                "--c-span-xs": 1
              } // fullspan
            : {} // tiling
        }
      };
      return acc;
    }, {}),
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 4
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with far-right table - 4 tables": {
    // NOTE: These tables are configured exactly the same
    ...["table1", "table2", "tableAd1", "table3", "table4"].reduce(
      (acc, key) => {
        acc[key] = {
          styles: {
            "--c-start-mx": 1,
            "--c-start-lg": 1,
            "--c-start-md": 1,
            "--c-start-sm": 1,
            "--c-start-xs": 1,
            "--c-span-mx": 16,
            "--c-span-lg": 16,
            "--c-span-md": 12,
            "--c-span-sm": 10,
            "--c-span-xs": 1
          },
          columns: {
            left: /tableAd1/.test(key)
              ? {
                  "--c-start-mx": 1,
                  "--c-start-lg": 1,
                  "--c-start-md": 1,
                  "--c-start-sm": 1,
                  "--c-start-xs": 1,
                  "--c-span-mx": 16,
                  "--c-span-lg": 16,
                  "--c-span-md": 12,
                  "--c-span-sm": 10,
                  "--c-span-xs": 1
                } // fullspan
              : {} // tiling
          }
        };
        return acc;
      },
      {}
    ),
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 5
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with far-right table - 4 tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    // NOTE: These tables are configured exactly the same
    ...["table1", "table2", "tableAd1", "table3", "table4"].reduce(
      (acc, key) => {
        acc[key] = {
          styles: {
            "--c-start-mx": 1,
            "--c-start-lg": 1,
            "--c-start-md": 1,
            "--c-start-sm": 1,
            "--c-start-xs": 1,
            "--c-span-mx": 16,
            "--c-span-lg": 16,
            "--c-span-md": 12,
            "--c-span-sm": 10,
            "--c-span-xs": 1
          },
          columns: {
            left: /tableAd1/.test(key)
              ? {
                  "--c-start-mx": 1,
                  "--c-start-lg": 1,
                  "--c-start-md": 1,
                  "--c-start-sm": 1,
                  "--c-start-xs": 1,
                  "--c-span-mx": 16,
                  "--c-span-lg": 16,
                  "--c-span-md": 12,
                  "--c-span-sm": 10,
                  "--c-span-xs": 1
                } // fullspan
              : {} // tiling
          }
        };
        return acc;
      },
      {}
    ),
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 6
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with far-right table - 6 tables": {
    // NOTE: These tables are configured exactly the same
    ...[
      "table1",
      "table2",
      "tableAd1",
      "table3",
      "table4",
      "table5",
      "table6"
    ].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: /tableAd1/.test(key)
            ? {
                "--c-start-mx": 1,
                "--c-start-lg": 1,
                "--c-start-md": 1,
                "--c-start-sm": 1,
                "--c-start-xs": 1,
                "--c-span-mx": 16,
                "--c-span-lg": 16,
                "--c-span-md": 12,
                "--c-span-sm": 10,
                "--c-span-xs": 1
              } // fullspan
            : {} // tiling
        }
      };
      return acc;
    }, {}),
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 7
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with far-right table - 6 tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    // NOTE: These tables are configured exactly the same
    ...[
      "table1",
      "table2",
      "tableAd1",
      "table3",
      "table4",
      "table5",
      "table6"
    ].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: /tableAd1/.test(key)
            ? {
                "--c-start-mx": 1,
                "--c-start-lg": 1,
                "--c-start-md": 1,
                "--c-start-sm": 1,
                "--c-start-xs": 1,
                "--c-span-mx": 16,
                "--c-span-lg": 16,
                "--c-span-md": 12,
                "--c-span-sm": 10,
                "--c-span-xs": 1
              } // fullspan
            : {} // tiling
        }
      };
      return acc;
    }, {}),
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 8
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with 2-col far-right table - 6 tables": {
    // NOTE: These tables are configured exactly the same
    ...[
      "table1",
      "table2",
      "tableAd1",
      "table3",
      "table4",
      "table5",
      "table6"
    ].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: /tableAd1/.test(key)
            ? {
                "--c-start-mx": 1,
                "--c-start-lg": 1,
                "--c-start-md": 1,
                "--c-start-sm": 1,
                "--c-start-xs": 1,
                "--c-span-mx": 16,
                "--c-span-lg": 16,
                "--c-span-md": 12,
                "--c-span-sm": 10,
                "--c-span-xs": 1
              } // fullspan
            : {} // tiling
        }
      };
      return acc;
    }, {}),
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 7
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with 2-col far-right table - 6 tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      }
    },
    // NOTE: These tables are configured exactly the same
    ...[
      "table1",
      "table2",
      "tableAd1",
      "table3",
      "table4",
      "table5",
      "table6"
    ].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: /tableAd1/.test(key)
            ? {
                "--c-start-mx": 1,
                "--c-start-lg": 1,
                "--c-start-md": 1,
                "--c-start-sm": 1,
                "--c-start-xs": 1,
                "--c-span-mx": 16,
                "--c-span-lg": 16,
                "--c-span-md": 12,
                "--c-span-sm": 10,
                "--c-span-xs": 1
              } // fullspan
            : {} // tiling
        }
      };
      return acc;
    }, {}),
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 8
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      labelStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with split tables": {
    // NOTE: These tables are configured almost exactly the same
    ...[
      "table1",
      "table2",
      "tableAd1",
      "table3",
      "table4",
      "table5",
      "table6"
    ].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: {}, // tiling
          main: {
            "--c-start-mx": 1,
            "--c-start-lg": 1,
            "--c-start-md": 1,
            "--c-start-sm": 1,
            "--c-span-mx": 8,
            "--c-span-lg": 8,
            "--c-span-md": 6,
            "--c-span-sm": 5
          },
          right: {
            "--c-start-mx": 9,
            "--c-start-lg": 9,
            "--c-start-md": 7,
            "--c-start-sm": 6,
            "--c-span-mx": 8,
            "--c-span-lg": 8,
            "--c-span-md": 6,
            "--c-span-sm": 5
          }
        }
      };
      return acc;
    }, {}),
    tableAd1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        } // fullspan
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 7
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with split tables with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        } // fullspan
      }
    },
    // NOTE: These tables are configured almost exactly the same
    ...[
      "table1",
      "table2",
      "tableAd1",
      "table3",
      "table4",
      "table5",
      "table6"
    ].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: {}, // tiling
          main: {
            "--c-start-mx": 1,
            "--c-start-lg": 1,
            "--c-start-md": 1,
            "--c-start-sm": 1,
            "--c-span-mx": 8,
            "--c-span-lg": 8,
            "--c-span-md": 6,
            "--c-span-sm": 5
          },
          right: {
            "--c-start-mx": 9,
            "--c-start-lg": 9,
            "--c-start-md": 7,
            "--c-start-sm": 6,
            "--c-span-mx": 8,
            "--c-span-lg": 8,
            "--c-span-md": 6,
            "--c-span-sm": 5
          }
        }
      };
      return acc;
    }, {}),
    tableAd1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        } // fullspan
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 8
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with split tables and 2-col far-right table": {
    // NOTE: These tables are configured almost exactly the same
    ...[
      "table1",
      "table2",
      "tableAd1",
      "table3",
      "table4",
      "table5",
      "table6"
    ].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: {}, // tiling
          main: {
            "--c-start-mx": 1,
            "--c-start-lg": 1,
            "--c-start-md": 1,
            "--c-start-sm": 1,
            "--c-span-mx": 8,
            "--c-span-lg": 8,
            "--c-span-md": 6,
            "--c-span-sm": 5
          },
          right: {
            "--c-start-mx": 9,
            "--c-start-lg": 9,
            "--c-start-md": 7,
            "--c-start-sm": 6,
            "--c-span-mx": 8,
            "--c-span-lg": 8,
            "--c-span-md": 6,
            "--c-span-sm": 5
          }
        }
      };
      return acc;
    }, {}),
    tableAd1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 7
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "Stacked strips with split tables and 2-col far-right table with top strip": {
    table0: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    // NOTE: These tables are configured almost exactly the same
    ...[
      "table1",
      "table2",
      "tableAd1",
      "table3",
      "table4",
      "table5",
      "table6"
    ].reduce((acc, key) => {
      acc[key] = {
        styles: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        },
        columns: {
          left: {}, // tiling
          main: {
            "--c-start-mx": 1,
            "--c-start-lg": 1,
            "--c-start-md": 1,
            "--c-start-sm": 1,
            "--c-span-mx": 8,
            "--c-span-lg": 8,
            "--c-span-md": 6,
            "--c-span-sm": 5
          },
          right: {
            "--c-start-mx": 9,
            "--c-start-lg": 9,
            "--c-start-md": 7,
            "--c-start-sm": 6,
            "--c-span-mx": 8,
            "--c-span-lg": 8,
            "--c-span-md": 6,
            "--c-span-sm": 5
          }
        }
      };
      return acc;
    }, {}),
    tableAd1: {
      styles: {
        "--c-start-mx": 1,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 16,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 16,
          "--c-span-lg": 16,
          "--c-span-md": 12,
          "--c-span-sm": 10,
          "--c-span-xs": 1
        }
      }
    },
    table9: {
      styles: {
        "--c-start-mx": 17,
        "--c-start-lg": 1,
        "--c-start-md": 1,
        "--c-start-sm": 1,
        "--c-start-xs": 1,
        "--c-span-mx": 4,
        "--c-span-lg": 16,
        "--c-span-md": 12,
        "--c-span-sm": 10,
        "--c-span-xs": 1,
        // START: r-spans
        "--r-span-mx": 8
      },
      columns: {
        left: {
          "--c-start-mx": 1,
          "--c-start-lg": 1,
          "--c-start-md": 1,
          "--c-start-sm": 1,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        },
        main: {
          "--c-start-mx": 1,
          "--c-start-lg": 9,
          "--c-start-md": 7,
          "--c-start-sm": 6,
          "--c-start-xs": 1,
          "--c-span-mx": 4,
          "--c-span-lg": 8,
          "--c-span-md": 6,
          "--c-span-sm": 5,
          "--c-span-xs": 1
        }
      },
      childStyles: {
        "--c-start-mx": 1,
        "--c-span-mx": 4
      }
    }
  },
  "======": {}
};

/**
 * Tiling styles are the layout settings for when columns responsively drop below
 * the main top table - think right most tables/columns as the page shrinks from max
 * These values are overwritten by the values in `layoutStyles`. So if you aren't seeing
 * changes at responsive sizes look at the top table in `layoutStyles`
 */
export const getTilingStyles = (styles) => {
  // NOTE: If tiling is needed at a col-width not specified here, add it
  const cols = {
    20: {
      1: 20,
      2: 10,
      3: 6,
      4: 5,
      5: 4,
      6: 6,
      7: 6,
      8: 5
    },
    16: {
      1: 16,
      2: 8,
      3: 5,
      4: 4,
      5: 5,
      6: 5,
      7: 4,
      8: 4
    },
    12: {
      1: 12,
      2: 6,
      3: 4,
      4: 6,
      5: 4,
      6: 4,
      7: 6,
      8: 6
    },
    10: {
      1: 10,
      2: 5,
      3: 10,
      4: 5,
      5: 5,
      6: 5,
      7: 5,
      8: 5
    },
    1: {
      1: 1,
      2: 1,
      3: 1,
      4: 1,
      5: 1,
      6: 1,
      7: 1,
      8: 1
    }
  };
  return [1, 2, 3, 4, 5, 6, 6, 7, 8].reduce((acc, v) => {
    acc[v] = {
      "--c-start-mx": 0,
      "--c-start-lg": 0, // This maybe a bug but if I leave the start value
      "--c-start-md": 0, // unassigned it doesn't fill in dense
      "--c-start-sm": 0, // but at zero it does what I want
      "--c-start-xs": 0,
      "--c-span-mx": cols[styles["--c-span-mx"]]
        ? cols[styles["--c-span-mx"]][v]
        : styles["--c-span-mx"],
      "--c-span-lg": cols[styles["--c-span-lg"]]
        ? cols[styles["--c-span-lg"]][v]
        : styles["--c-span-lg"],
      "--c-span-md": cols[styles["--c-span-md"]]
        ? cols[styles["--c-span-md"]][v]
        : styles["--c-span-md"],
      "--c-span-sm": cols[styles["--c-span-sm"]]
        ? cols[styles["--c-span-sm"]][v]
        : styles["--c-span-sm"],
      "--c-span-xs": cols[styles["--c-span-xs"]]
        ? cols[styles["--c-span-xs"]][v]
        : styles["--c-span-xs"]
    };
    return acc;
  }, {});
};

// NOTE: By default, the desktop ordering will stop at sm and so mobile will be active at: sm, xs
// However, some layouts need to transition at md so mobile will be active at: md, sm, xs
const desktopOrderingExceptions = {
  "Extra-wide center": {
    md: false
  },
  "Extra-wide center - 2-col table at left": {
    md: false
  },
  "Extra-wide center - 3-col table full width": {
    md: false
  },
  "Super-wide right": {
    md: false
  },
  "Super-wide left": {
    md: false
  },
  "Wide left - 2-col table at center": {
    sm: true
  },
  "Double wide": {
    sm: true
  },
  "Double wide with far-right table": {
    sm: true
  },
  "Extra-wide center - 3 separate tables": {
    sm: true
  },
  Tiling: {
    sm: true
  },
  "Stacked strips with far-right table - 2 tables": {
    sm: true
  },
  "Stacked strips with far-right table - 4 tables": {
    sm: true
  },
  "Stacked strips with far-right table - 6 tables": {
    sm: true
  },
  "Stacked strips with 2-col far-right table - 6 tables": {
    sm: true
  },
  "Stacked strips with split tables": {
    sm: true
  },
  "Stacked strips with split tables and 2-col far-right table": {
    sm: true
  }
};
Object.keys(desktopOrderingExceptions).forEach((key) => {
  desktopOrderingExceptions[`${key} with top strip`] =
    desktopOrderingExceptions[key];
});

// NOTE: The only setting that will work comprehensively by making a change here
// is --table-row-gap. Changes to any other one of these would require additional work.
export const gridGapStyles = {
  default: {
    "--table-row-gap": "32px",
    "--table-col-gap": "32px",
    "--feature-row-gap": "32px",
    "--feature-col-gap": "32px"
  },
  "Stacked strips with far-right table - 2 tables": {
    "--table-row-gap": "64px"
  },
  "Stacked strips with far-right table - 4 tables": {
    "--table-row-gap": "64px"
  },
  "Stacked strips with far-right table - 6 tables": {
    "--table-row-gap": "64px"
  },
  "Stacked strips with 2-col far-right table - 6 tables": {
    "--table-row-gap": "64px"
  },
  "Stacked strips with split tables": {
    "--table-row-gap": "64px"
  },
  "Stacked strips with split tables and 2-col far-right table": {
    "--table-row-gap": "64px"
  }
};
Object.keys(gridGapStyles)
  .filter((_) => _ !== "default")
  .forEach((key) => {
    gridGapStyles[`${key} with top strip`] = gridGapStyles[key];
  });

/*
 * @param {string} layout - A layout name like "Wide center - 3-col table at left"
 * @param {string} bp - One of the supported breakpoints: mx, lg, md, sm, xs
 *
 * @returns {boolean} - Whether to use desktop ordering or not.
 *
 */
export const shouldUseDesktopOrdering = (layout = "", bp = "") => {
  const useDesktopOrderingException = get(
    desktopOrderingExceptions,
    `${layout}.${bp}`,
    undefined
  );

  if (useDesktopOrderingException !== undefined) {
    return useDesktopOrderingException;
  }

  const useDesktopOrderingDefault = IMPLICIT_GRID_INFO.filter(
    (info) => info.bp === bp
  ).reduce((acc, info) => {
    acc = info.useDesktopOrdering;
    return acc;
  }, true);

  return useDesktopOrderingDefault;
};

export const adminValidationMessages = {
  "add-features": {
    msg: "Please add features to this column/table",
    color: "#FFF",
    bg: "#d8070e"
  },
  "far-right": {
    msg: "Avoid full-width images in this column when there are so few features. If there are 2 features, use the same preset for both.",
    color: "#FFF",
    bg: "#d8070e"
  },
  "same-preset": {
    msg: "Use the same preset for both features so they have similar height when placed side by side at smaller breakpoints.",
    color: "#FFF",
    bg: "#d8070e"
  },
  "full-width": {
    msg: "Avoid full-width images when there is only one feature in this column.",
    color: "#FFF",
    bg: "#d8070e"
  },
  "three-items": {
    msg: "Columns/Tables with 3 features don't layout perfectly at smaller screen sizes - maybe add another?",
    color: "#333",
    bg: "#f2dede"
  },
  "odd-items": {
    msg: "Columns containing 5 or 7 items don't layout well at smaller sizes",
    color: "#FFF",
    bg: "#d8070e"
  },
  "too-many": {
    msg: "Please rethink making a column/table of more than 8 items",
    color: "#FFF",
    bg: "#d8070e"
  }
};
