import get from "lodash.get";
import { addWww } from "~/components/utilities/www-remover";
import { isImageUrl } from "~/shared-components/story-card/_children/Image.helpers";
import { transform as transformPrismQuery } from "./transform-prism-query";

const getPromoItems = (promo_items) =>
  Object.keys(promo_items).reduce((acc, key) => {
    const item = promo_items[key];
    const { url, type } = item;
    acc[key] = !type && isImageUrl(url) ? { ...item, type: "image" } : item;
    return acc;
  }, {});

// NOTE: If recommendations, rename items to match prism queries
const transformRecommendations = (data) => {
  const items = get(data, "recommendations", []).map((item) => {
    const {
      _id,
      canonical_url,
      credits,
      description,
      display_date,
      first_publish_date,
      headlines,
      label,
      label_display,
      promo_items,
      publish_date,
      subtype,
      type
    } = item;

    // Map this to standard prism response
    return {
      _id,
      canonical_url: addWww(canonical_url),
      credits,
      description,
      display_date: display_date || publish_date || first_publish_date,
      headlines,
      label,
      label_display,
      promo_items: getPromoItems(promo_items),
      publish_date: publish_date || first_publish_date,
      subtype,
      type
    };
  });

  return {
    count: items.length,
    algorithm: data.algorithm,
    items
  };
};

export const transform = (data) => {
  data = transformRecommendations(data);
  data = transformPrismQuery(data);
  return data;
};
